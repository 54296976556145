import {
  SelectorNumberFn,
  SelectorStringFn,
  SortDirection,
} from '@epicuro-next/models/sorting';

export function compareStrings<T>(selector: SelectorStringFn<T>) {
  return (a: T, b: T, sortDirection: SortDirection) => {
    if (sortDirection === SortDirection.Asc) {
      return selector(a).localeCompare(selector(b));
    }

    return selector(b).localeCompare(selector(a));
  };
}

export function compareNumbers<T>(selector: SelectorNumberFn<T>) {
  return (a: T, b: T, sortDirection: SortDirection) => {
    if (sortDirection === SortDirection.Asc) {
      return selector(a) - selector(b);
    }

    return selector(b) - selector(a);
  };
}

export function compareDates<T>(selector: SelectorStringFn<T>) {
  return (a: T, b: T, sortDirection: SortDirection) => {
    const dateA = new Date(selector(a));
    const dateB = new Date(selector(b));
    if (sortDirection === SortDirection.Asc) {
      return dateA.getTime() - dateB.getTime();
    }

    return dateB.getTime() - dateA.getTime();
  };
}
