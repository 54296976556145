export enum MediaObjectPosition {
  Bottom = 'object-bottom',
  Center = 'object-center',
  CenterTop = 'object-center-top',
  CenterBottom = 'object-center-bottom',
  Left = 'object-left',
  LeftBottom = 'object-left-bottom',
  LeftTop = 'object-left-top',
  Right = 'object-right',
  RightBottom = 'object-right-bottom',
  RightTop = 'object-right-top',
  Top = 'object-top',
}
