/**
 * Parameter key for the site
 */
export const SITE_ID = 'siteId';
/**
 * Parameter key for the sub-site
 */
export const SUB_SITE_ID = 'subSiteId';
/**
 * Parameter key for the account
 */
export const ACCOUNT_ID = 'accountId';
/**
 * Parameter key for the basket
 */
export const BASKET_ID = 'basketId';
/** Shows current version of the app */
export const APP_VERSION = 'appVersion';

/**
 * Session state type
 */
export type SessionState = {
  // because of https://github.com/Microsoft/TypeScript/issues/14951 TypeScript should be able to infer the index signature of a static type
  [key: string]: string | number | null;
  [SITE_ID]: string | null;
  [SUB_SITE_ID]: string | null;
  [ACCOUNT_ID]: string | null;
  [BASKET_ID]: string | null;
  [APP_VERSION]: string | null;
};
