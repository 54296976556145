import {
  IKeyValue,
  IUserContext,
} from '@epicuro-next/models/platform/monitoring';

/**
 * Abstraction of a service to handle context for the monitoring services
 */
export abstract class AbstractContextService {
  /**
   * Sets the user information
   * @param user
   */
  public abstract setUser(user: IUserContext): void;
  /**
   * Sets the tags for the app
   * @param tags
   */
  public abstract setTags(tags: IKeyValue): void;
  /**
   * Sets the release version to log
   */
  public abstract setRelease(releaseVersion: string): void;
}
