import { getValue } from './imports';

export const concatenateObjectFields = <T extends {}>(
  separator: string,
  sourceObject: T,
  fields: string[],
  fieldsMap: Record<string, keyof T>,
) =>
  fields
    .map((field) => fieldsMap?.[field] || (field as keyof T))
    .map((field) => getValue(sourceObject, field as string))
    .filter(Boolean)
    .join(separator);
