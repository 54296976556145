import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SessionState } from './model';
import {
  getAccountId,
  getAppVersion,
  getSiteId,
  getSubSiteId,
  sessionFeatureKey,
} from './session.reducer';

/**
 * Selects the whole session state slice
 */
export const selectSessionState =
  createFeatureSelector<SessionState>(sessionFeatureKey);

/**
 * Selects the site id
 */
export const selectSiteId = createSelector(selectSessionState, getSiteId);

export const selectSiteName = createSelector(selectSiteId, (site) => {
  if (!site) {
    return window.location.pathname;
  }

  return site;
});

/**
 * Selects the sub site id
 */
export const selectSubSiteId = createSelector(selectSessionState, getSubSiteId);

/**
 * Selects the account id
 */
export const selectAccountId = createSelector(selectSessionState, getAccountId);

export const selectAppVersion = createSelector(
  selectSessionState,
  getAppVersion,
);
