type ValidationResult = {
  message?: string | null;
  severity: unknown;
  /** Validation message. */
  translationKey?: string | null;
  /** Validation result values. */
  values?: string[] | null;
};

export const messageToTranslatedOrOriginal =
  (translations: Record<string, string>) =>
  <T extends ValidationResult>(validation: T) => {
    const translatedMessage = translations[
      validation.translationKey as string
    ]?.replace(/\{\d+}/g, (match) => {
      return (
        (validation.values ?? [])[+match.substring(1, match.length - 1)] ||
        match
      );
    });
    return {
      ...validation,
      message: translatedMessage || validation.message,
    };
  };
