import { EnvironmentSettings } from '@epicuro-next/platform/environment';

import { GTMConfig } from '../interfaces/gtm-config';

export function GTMConfigFactory(
  environmentSettings: EnvironmentSettings,
): GTMConfig | null {
  if (!environmentSettings.gtmKey) {
    return null;
  }

  return {
    key: environmentSettings.gtmKey,
    dataLayerName: environmentSettings.gtmDataLayerName ?? 'dataLayer',
    auth: environmentSettings.gtmAuth,
    previewEnv: environmentSettings.gtmPreview,
    cookiesWin: environmentSettings.gtmCookiesWin,
    v4Enabled: environmentSettings.gtmKeyV4?.length ? true : false,
    keyV4: environmentSettings.gtmKeyV4,
    dataLayerNameV4: environmentSettings.gtmDataLayerNameV4,
  };
}
