import { Injectable } from '@angular/core';
import { LoggerService } from '@epicuro-next/platform/monitoring';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import { ILanguageEntry } from '../i18n.interface';
import { I18nService } from '../i18n.service';
import { getCurrentLanguage } from '../i18n.utils';
import { getLocaleDateFormat } from '../mappings';

import { languageActions } from './i18n.actions';

/**
 * i18n effects
 */
@Injectable()
export class LanguageEffects {
  /**
   * Dependencies
   */
  constructor(
    private i18nService: I18nService,
    private action$: Actions,
    private loggerService: LoggerService,
  ) {}

  /**
   * effect to load the available languages
   */
  public loadLanguages$ = createEffect(() =>
    this.action$.pipe(
      ofType(languageActions.loadLanguages),
      switchMap(() =>
        this.i18nService.getAvailableLanguages().pipe(
          map((languages) =>
            languages
              .map((language) => ({
                ...language,
                dateFormat: getLocaleDateFormat(language.id),
              }))
              .sort((prev, current) => prev.name.localeCompare(current.name)),
          ),
          map((availables: ILanguageEntry[]) =>
            languageActions.setAvailableLanguages({
              availables,
            }),
          ),
          catchError(() => {
            return of(languageActions.loadLanguagesFail());
          }),
        ),
      ),
    ),
  );
  /**
   * effect to signal failure during loading of languages
   */
  public loadLanguagesFail$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(languageActions.loadLanguagesFail),
        tap(() => {
          this.loggerService.exception({
            error: new Error('Error during loading of available languages'),
          });
        }),
      ),
    {
      dispatch: false,
    },
  );

  /**
   * effect to set the language
   */
  public setLanguage$ = createEffect(() =>
    this.action$.pipe(
      ofType(languageActions.setLanguage),
      map(({ selected }) => {
        const current = getCurrentLanguage();
        const validSelected = selected ?? current;
        localStorage.setItem('phoenix-language', validSelected);
        localStorage.removeItem('phoenix-login-language');

        return [validSelected, current];
      }),
      filter(([validSelected, current]) => {
        if (validSelected?.toLowerCase() !== current.toLowerCase()) {
          this.reload();
          return false;
        }

        return true;
      }),
      map(() => languageActions.languageReady()),
    ),
  );

  public setLoginLanguage$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(languageActions.setLoginLanguage),
        map(({ selected }) => {
          const current = getCurrentLanguage();
          const validSelected = selected ?? current;
          localStorage.setItem('phoenix-language', validSelected);
          localStorage.setItem('phoenix-login-language', validSelected);

          return [validSelected, current];
        }),
        tap(([validSelected, current]) => {
          if (validSelected?.toLowerCase() !== current.toLowerCase()) {
            this.reload();
            return false;
          }

          return true;
        }),
      ),
    { dispatch: false },
  );

  /**
   * Called upon ngrx initializes this feature state slice
   */
  public ngrxOnInitEffects() {
    return languageActions.loadLanguages();
  }

  private reload() {
    window.location.reload();
  }
}
