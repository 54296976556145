import { BasketItem, OrderType } from 'graphql-api/baskets-api-interfaces';

export const isPreOrderBasketItem = (basketItem: BasketItem) =>
  basketItem.orderType === OrderType.Future;

export const isAllBasketItemsForPreOrder = (basketItems: BasketItem[]) =>
  !basketItems.some((item) => !isPreOrderBasketItem(item));

export const getOrderTypeByBasketItems = (basketItems: BasketItem[]) =>
  isAllBasketItemsForPreOrder(basketItems) ? OrderType.Future : OrderType.Now;
