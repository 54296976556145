import { Configurations } from '../models/config.model';

export const ACCOUNTS_FEATURE_NAME = 'accounts';

export enum AccountsConfigurationKeys {
  HighlightFoundAddress = 'highlight-found-address',
  IsAccountsInMenuClickable = 'is-accounts-in-menu-clickable',
  SearchInputDelay = 'search-input-delay',
  ShowAccountIdsInNewLine = 'show-account-ids-in-new-line',
  ShowDeselectAll = 'show-deselect-all',
  ShowMerchandiseBackground = 'merchandise-background-enabled',
  ShowSelectedAccounts = 'show-selected-accounts',
  SingleSelectionKey = 'single-selection',
  AddressShownProperties = 'address-shown-properties',
}

export interface AccountsConfigurations
  extends Configurations<AccountsConfigurationKeys> {
  [AccountsConfigurationKeys.HighlightFoundAddress]: boolean;
  [AccountsConfigurationKeys.IsAccountsInMenuClickable]: boolean;
  [AccountsConfigurationKeys.SearchInputDelay]: number;
  [AccountsConfigurationKeys.ShowAccountIdsInNewLine]: boolean;
  [AccountsConfigurationKeys.ShowDeselectAll]: boolean;
  [AccountsConfigurationKeys.ShowMerchandiseBackground]: boolean;
  [AccountsConfigurationKeys.ShowSelectedAccounts]: boolean;
  [AccountsConfigurationKeys.SingleSelectionKey]: boolean;
  [AccountsConfigurationKeys.AddressShownProperties]: string[];
}

export const ACCOUNTS_CONFIGURATIONS_DEFAULT_VALUES: AccountsConfigurations = {
  [AccountsConfigurationKeys.HighlightFoundAddress]: false,
  [AccountsConfigurationKeys.IsAccountsInMenuClickable]: false,
  [AccountsConfigurationKeys.SearchInputDelay]: 1600,
  [AccountsConfigurationKeys.ShowAccountIdsInNewLine]: false,
  [AccountsConfigurationKeys.ShowDeselectAll]: false,
  [AccountsConfigurationKeys.ShowMerchandiseBackground]: false,
  [AccountsConfigurationKeys.ShowSelectedAccounts]: false,
  [AccountsConfigurationKeys.SingleSelectionKey]: false,
  [AccountsConfigurationKeys.AddressShownProperties]: [
    'displayName',
    'addressId',
  ],
};
