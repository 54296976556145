import { InjectionToken } from '@angular/core';
import { AbstractLoggerService } from '@epicuro-next/models/platform/monitoring';

import { AbstractAnalyticsService } from './abstract/abstract-analytics.service';
import { AbstractContextService } from './abstract/abstract-context.service';

/**
 * Injection token to be used for registering logger context service.
 * Context service is supposed to be used for setting general information(e.g. user id, global tags)
 * This service then will be used by MonitoringContextService
 */
export const CONTEXT_TRANSPORT_TOKEN: InjectionToken<AbstractContextService> =
  new InjectionToken('CONTEXT_TRANSPORT_TOKEN');

/**
 * Injection token to be used for adding logging service.
 * This service then will be used by LoggerService
 */
export const LOGGER_TRANSPORT_TOKEN: InjectionToken<AbstractLoggerService> =
  new InjectionToken('LOGGER_TRANSPORT_TOKEN');

/**
 * Injection token to be used for adding analytics service.
 * This service then will be used by AnalyticsService
 */
export const ANALYTICS_TRANSPORT_TOKEN: InjectionToken<AbstractAnalyticsService> =
  new InjectionToken('ANALYTICS_TRANSPORT_TOKEN');

/**
 * Injection token to be used for analytics API endpoint.
 */
export const ANALYTICS_API_ADDRESS: InjectionToken<string> = new InjectionToken(
  'ANALYTICS_API_ADDRESS',
);

/** Token for getting SAS token */
export const REPORTING_TOKEN_API_TOKEN = new InjectionToken<string>(
  'Reporting Token API url',
);
