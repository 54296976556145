import { isNullish, memoize } from '@epicuro-next/utilities';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  ConfigurationKey,
  Configurations,
  ConfigurationValue,
} from '../models/config.model';

import {
  configurationFeatureKey,
  ConfigurationState,
} from './configuration.reducer';

type ConfigurationSelectorParameters = {
  namespace: string;
  featureName: string;
  defaultValue?: ConfigurationValue;
};

/**
 * State Slice selector
 */
export const selectConfigurationsState =
  createFeatureSelector<ConfigurationState>(configurationFeatureKey);

/**
 * Configurations features list
 */
export const selectConfigurationsFeatureNames = createSelector(
  selectConfigurationsState,
  (state) => Object.keys(state),
);

/**
 * Parameterized selector for getting the value of a specific configuration value
 */
export const selectConfigurationValue = createSelector(
  selectConfigurationsState,
  (
    configurations: ConfigurationState,
    { namespace, featureName, defaultValue }: ConfigurationSelectorParameters,
  ) => {
    return configurations[namespace]?.[featureName] ?? defaultValue ?? null;
  },
);

export const selectConfigurationNamespace = memoize((namespace: string) =>
  createSelector(selectConfigurationsState, (configurations) => {
    if (isNullish(configurations)) return undefined;
    return configurations[namespace];
  }),
);

export const selectConfigurationValueFrom = memoize(
  <K extends ConfigurationKey, V extends Configurations<K> = never>(
    namespace: string,
    key: K,
    defaultValue: V[K],
  ) =>
    createSelector(
      selectConfigurationNamespace(namespace),
      (namespaceState) => {
        if (!namespaceState) return undefined;
        return (namespaceState as V)[key] ?? defaultValue;
      },
    ),
  (n, k, d) => `${n}_${k.toString()}_${JSON.stringify(d)}`,
);
