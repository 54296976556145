import { getValue, setValue } from './imports';

export function pick<T extends object>(object: T, keys: string[]) {
  return keys.reduce((acc, key) => {
    const value = getValue(object, key);

    setValue(acc, key, value, { merge: true });

    return acc;
  }, {});
}
