import { HttpRequest } from '@angular/common/http';

export const UNPROTECTED_RESOURCES = [
  `/api/v2/configuration/theme`,
  `/api/v2/configuration/environment`,
  `/api/v2/configuration/orders`,
  `/api/token`,
];

export function isUnprotectedResource(request: HttpRequest<unknown>) {
  return UNPROTECTED_RESOURCES.some((resource) =>
    request.url.includes(resource),
  );
}
