// tslint:disable:no-non-null-assertion
import { Injectable } from '@angular/core';
import {
  pulseConfigurationDefaultValues,
  PulseConfigurationKeys,
  PulseConfigurations,
  PULSE_FEATURE_NAME,
} from '@epicuro-next/constants/rule-engine/pulse-rules';
import { IRuleValue, RuleEngine } from '@epicuro-next/platform/rule-engine';
// tslint:disable-next-line:nx-enforce-module-boundaries
import {
  selectAccountId,
  selectSiteId,
} from '@epicuro-next/state/session-state';
import {
  arrayIsNotEmpty,
  isNonNullish,
  isNullish,
} from '@epicuro-next/utilities';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

import { Configurations } from '../models/config.model';
import { ConfigurationsService } from '../services/configurations/configurations.service';

import * as actions from './configuration.actions';
import { updateRules } from './configuration.actions';
import { selectConfigurationsState } from './configuration.selectors';
import { isPartOfBaseRules } from './utils';

@Injectable()
export class ConfigurationEffects {
  public initConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.initConfig),
      filter(({ featureName }) => !isPartOfBaseRules(featureName)),
      switchMap(({ featureName }) =>
        this.store.pipe(
          select(selectAccountId),
          filter(isNonNullish),
          take(1),
          map(() => featureName),
        ),
      ),
      map((featureName) => {
        return actions.loadConfig({ featureName });
      }),
    ),
  );

  public initBaseRulesConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.initConfig),
      filter(({ featureName }) => isPartOfBaseRules(featureName)),
      filter(({ featureName }) => {
        return !this.ruleFetchingInProgressSet.has(featureName);
      }),
      tap(({ featureName }) => this.ruleFetchingInProgressSet.add(featureName)),
      mergeMap(({ featureName }) =>
        this.ruleEngineService.getValues(featureName).pipe(
          map((values) => {
            const featureConfigs: Configurations = {};
            values.forEach(
              (configValue) =>
                (featureConfigs[configValue.name!] = configValue.value),
            );
            return { [featureName]: featureConfigs };
          }),
          map((featureConfigs) => {
            return actions.initConfigSuccess({
              configurations: { ...featureConfigs },
              namespaces: Object.keys(featureConfigs),
            });
          }),
          catchError((error) =>
            of(actions.initConfigFailure({ error: error.message })),
          ),
        ),
      ),
    ),
  );

  private readonly allRulesFetchAtOnce$ = this.configurationsService
    .getConfigValue<
      PulseConfigurations,
      PulseConfigurationKeys.AllRulesFetchAtOnce
    >({
      namespace: PULSE_FEATURE_NAME,
      featureName: PulseConfigurationKeys.AllRulesFetchAtOnce,
      defaultValue:
        pulseConfigurationDefaultValues[
          PulseConfigurationKeys.AllRulesFetchAtOnce
        ],
    })
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  // update rules when account, site changes
  // context is added in rule-interceptor
  public broadcastToUpdateRules$ = createEffect(() =>
    combineLatest([
      this.store.pipe(select(selectAccountId)),
      this.store.pipe(select(selectSiteId)),
      this.allRulesFetchAtOnce$.pipe(distinctUntilChanged()),
    ]).pipe(
      filter(
        ([accountId, siteId]) =>
          isNonNullish(accountId) && isNonNullish(siteId),
      ),
      map(([, , allRulesFetchAtOnce]) => {
        return updateRules({
          allRulesFetchAtOnce,
        });
      }),
    ),
  );

  public updateRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRules),
      tap(() => this.ruleFetchingInProgressSet.clear()),
      switchMap(({ allRulesFetchAtOnce }) =>
        allRulesFetchAtOnce ? this.getAllRules$(false) : this.getRule$(false),
      ),
    ),
  );

  private ruleFetchingInProgressSet = new Set<string>();

  public loadConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadConfig),
      concatLatestFrom(() => this.allRulesFetchAtOnce$),
      concatMap(([, allRulesFetchAtOnce]) =>
        allRulesFetchAtOnce ? this.getAllRules$(true) : this.getRule$(true),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private ruleEngineService: RuleEngine,
    private configurationsService: ConfigurationsService,
    private store: Store,
  ) {}

  private getConfigurationStateFeatures(skipLoaded: boolean) {
    return this.store.pipe(
      select(selectConfigurationsState),
      take(1),
      map((configurationState) =>
        skipLoaded
          ? Object.entries(configurationState)
              .filter(([, value]) => isNullish(value))
              .map(([featureKey]) => featureKey)
          : Object.keys(configurationState),
      ),
    );
  }

  /**
   * Load all rules at once
   * @param skipLoaded true - we do not get values for rules that already are loaded
   * Load new rules whenever: AccountId, SiteId have changed
   */
  private getAllRules$(skipLoaded: boolean) {
    return this.getConfigurationStateFeatures(skipLoaded).pipe(
      filter(arrayIsNotEmpty),
      switchMap((featureNames: string[]) =>
        this.ruleEngineService.getAllRules(featureNames).pipe(
          map((ruleValues) => {
            const configurations = {
              ...ruleValues
                .filter(
                  ({ namespace, name }) =>
                    isNonNullish(namespace) && isNonNullish(name),
                )
                .concat(
                  featureNames.map((featureName) => ({
                    namespace: featureName,
                  })),
                )
                .reduce(
                  (
                    acc: { [featureName: string]: Configurations },
                    curr: IRuleValue,
                  ) => ({
                    ...acc,
                    [curr.namespace!]: {
                      ...acc[curr.namespace!],
                      [curr.name!]: curr.value,
                    },
                  }),
                  {},
                ),
            };

            return actions.initConfigSuccess({
              configurations,
              namespaces: Object.keys(configurations),
            });
          }),
          catchError((error) =>
            of(actions.initConfigFailure({ error: error.message })),
          ),
        ),
      ),
    );
  }

  /**
   *
   * @param skipLoaded true - we do not get values for rules that already are loaded
   * @param featureName that has to be loaded even if not yet in state apart from case
   * when rule is already loading
   * @returns Rules for current feature
   */
  private getRule$(skipLoaded: boolean) {
    return this.getConfigurationStateFeatures(skipLoaded).pipe(
      switchMap((features) => features),
      // filter out rules fetching of which are in progress
      filter((rule) => {
        return !this.ruleFetchingInProgressSet.has(rule);
      }),
      tap((rule) => this.ruleFetchingInProgressSet.add(rule)),
      mergeMap((rule) =>
        this.ruleEngineService.getValues(rule).pipe(
          map((values) => {
            const featureConfigs: Configurations = {};
            values.forEach(
              (configValue) =>
                (featureConfigs[configValue.name!] = configValue.value),
            );
            return { [rule]: featureConfigs };
          }),
          // tslint:disable-next-line:no-identical-functions
          map((featureConfigs) => {
            return actions.initConfigSuccess({
              configurations: { ...featureConfigs },
              namespaces: Object.keys(featureConfigs),
            });
          }),
          tap(() => this.ruleFetchingInProgressSet.delete(rule)),
          catchError((error) =>
            of(actions.initConfigFailure({ error: error.message })),
          ),
        ),
      ),
    );
  }
  // tslint:enable:no-non-null-assertion
}
