import { IErrorLog, IMessageLog } from './interfaces/log';

/**
 * Abstraction of a LoggerService
 */
export abstract class AbstractLoggerService {
  /**
   * Logs an error
   * @param errorLog
   */
  public abstract exception(errorLog: IErrorLog): void;
  /**
   * Logs a debug message
   * @param messageLog
   */
  public abstract message(messageLog: IMessageLog): void;
}
