import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isAPIRequest } from '@epicuro-next/utilities';
import { select, Store } from '@ngrx/store';
import { headerCase } from 'header-case';
import { switchMap, take } from 'rxjs/operators';

import { LanguageState, selectLanguage } from '../store';

/**
 * Intercept API requests and append the accept language header to it
 */
@Injectable()
export class AcceptLanguageInterceptor implements HttpInterceptor {
  constructor(private store: Store<LanguageState>) {}

  /**
   * The intercept method to be called by angular
   */
  public intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    const hostName = window.location.hostname;
    if (!isAPIRequest(request, hostName)) {
      return next.handle(request);
    }

    return this.store.pipe(
      select(selectLanguage),
      take(1),
      switchMap((selectedLanguage) => {
        let headers = request.headers;
        headers = headers.append(
          headerCase(`Accept-Language`),
          selectedLanguage,
        );
        return next.handle(request.clone({ headers }));
      }),
    );
  }
}
