@if ( enableProgressiveLoad && sortedImageBreakPoints.length === 0 ?
previewLoading : loading ) {
<div class="loader" data-e2e="image-loader">
  <fa-icon class="spin-animation" [icon]="loaderIcon"></fa-icon>
</div>
} @if (!loadFailed) {
<picture class="w-100 h-100">
  @for (item of sortedImageBreakPoints; track item) {
  <source [media]="getMedia(item.mediaMinWidth)" [srcset]="getSrcSet(item)" />
  } @if ( enableProgressiveLoad && sortedImageBreakPoints.length === 0) {
  <img
    class="progressive"
    atxProgressiveImage
    atxVisible
    [visibleEnabled]="!imageVisible"
    [imgUrl]="processedImageUrl"
    [loadOriginal]="imageVisible && maxWidth != null"
    [style.max-height]="maxHeight !== undefined ? maxHeight + 'px' : undefined"
    [alt]="title"
    (error)="loadFail()"
    (load)="onPreviewLoaded()"
    (visible)="imageIntersected($event)"
    (imageLoaded)="loadComplete()"
    #imgRef
  />
  } @else {
  <img
    [src]="processedImageUrl"
    (load)="loadComplete()"
    (error)="loadFail()"
    [style.max-height]="maxHeight !== undefined ? maxHeight + 'px' : undefined"
    [alt]="title"
    loading="lazy"
    #imgRef
  />
  }
</picture>
} @if (loadFailed && !fallbackImageUrl) {
<div class="image-failed" data-e2e="image-failed">
  <fa-icon
    [icon]="['fas', 'image-polaroid']"
    [size]="failedLoadIconSize"
  ></fa-icon>
</div>
} @if (loadFailed && fallbackImageUrl) {
<picture class="w-100 h-100" data-e2e="image-failed">
  <img
    class="h-100 w-100"
    [src]="fallbackImageUrl"
    [alt]="title"
    loading="lazy"
  />
</picture>
}
