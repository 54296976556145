import { Configurations } from '../models/config.model';

export const NAMESPACE_MERCHANDISE_PRICES = 'merchandise-prices';

export enum MerchandisePricesConfigurationKeys {
  UseMerchandiseForPrices = 'use-merchandise-for-prices',
}

export interface MerchandisePricesConfigurations
  extends Configurations<MerchandisePricesConfigurationKeys> {
  [MerchandisePricesConfigurationKeys.UseMerchandiseForPrices]: boolean;
}
