import {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core';

/** basketManager icon */
export const faBasketManager: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'basketManager' as IconName,
  icon: [
    16,
    16,
    [],
    '',
    'M12.999,11 L4.499,11 C3.995,11 3.569,10.624 3.507,10.124 L2.617,3 L1,3 C0.448,3 0,2.552 0,2 C0,1.448 0.448,1 1,1 L3.5,1 C4.004,1 4.43,1.376 4.492,1.876 L5.383,9 L12.246,9 L14.039,2.725 C14.191,2.194 14.744,1.887 15.275,2.038 C15.806,2.189 16.114,2.743 15.962,3.274 L13.962,10.274 C13.839,10.703 13.447,10.999 13,10.999 L12.999,11 Z M3.999,13.5 C3.999,12.672 4.671,12 5.499,12 C6.327,12 6.999,12.672 6.999,13.5 C6.999,14.328 6.327,15 5.499,15 C4.671,15 3.999,14.328 3.999,13.5 Z M11.499,12 C12.327,12 12.999,12.672 12.999,13.5 C12.999,14.328 12.327,15 11.499,15 C10.671,15 9.999,14.328 9.999,13.5 C9.999,12.672 10.671,12 11.499,12 Z M9.02454908,5 L7.77735052,6.23666667 C8.12091842,6.506 8.55390811,6.66666667 9.02454908,6.66666667 C9.88957927,6.66666667 10.6295395,6.12395848 10.915394,5.36368763 L10.9635899,5.21866667 L11.999,5.21866667 C11.7374581,6.612 10.5050511,7.66666667 9.02454908,7.66666667 C8.34985164,7.66666667 7.72634648,7.44742667 7.22314321,7.07698667 L7.05995921,6.948 L5.999,8 L5.999,5 L9.02454908,5 Z M11.999,1 L11.999,4 L8.97345092,4 L10.2206495,2.76333333 C9.87708158,2.494 9.44409189,2.33333333 8.97345092,2.33333333 C8.10842073,2.33333333 7.36846053,2.87604152 7.08260598,3.63631237 L7.03441013,3.78133333 L5.999,3.78133333 C6.26054191,2.388 7.4929489,1.33333333 8.97345092,1.33333333 C9.64814836,1.33333333 10.2716535,1.55257333 10.7748568,1.92301333 L10.9380408,2.052 L11.999,1 Z',
  ],
};
