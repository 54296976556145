import { HttpRequest } from '@angular/common/http';

const API_URL_PATH = '/api/';

/**
 * Determines whether the intercepted request is an API request
 */
export function isAPIRequest(request: HttpRequest<unknown>, hostName?: string) {
  return (
    request.url.startsWith(API_URL_PATH) ||
    isGoingToCurrentHostAndApi(request, hostName)
  );
}

function isGoingToCurrentHostAndApi(
  request: HttpRequest<unknown>,
  hostName?: string,
) {
  if (!hostName) {
    return false;
  }
  try {
    const url = new URL(request.url);
    return (
      hostName &&
      url.hostname === hostName &&
      url.pathname.startsWith(API_URL_PATH)
    );
  } catch {
    return false;
  }
}
