import {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core';

/** pin icon */
export const faThinUnpin: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'thin unpin' as IconName,
  icon: [
    14,
    14,
    [],
    '',
    [
      'M5.99627 4.56713L4.97622 5.42506C4.6676 5.15477 4.27371 5.00683 3.8565 5.00683C3.40691 5.00683 2.98428 5.17839 2.66647 5.48991L2.64229 5.5136C2.50303 5.65001 2.50303 5.87125 2.64229 6.00766L4.95105 8.27024L2.70248 10.4738C2.65748 10.5191 1.59316 11.594 0.893437 12.4493C0.22708 13.2636 0.095306 13.4128 0.0884604 13.4205C-0.0352557 13.5586 -0.0284103 13.7668 0.104005 13.8972C0.173386 13.9655 0.264872 14 0.356643 14C0.440285 14 0.524142 13.9713 0.591597 13.9136C0.598659 13.9076 0.749665 13.7792 1.58261 13.1247C2.45526 12.4389 3.55202 11.3958 3.60186 11.3483L5.84679 9.14821L8.0319 11.2896C8.1015 11.3579 8.19277 11.392 8.28397 11.392C8.37517 11.392 8.46651 11.3579 8.53604 11.2896L8.56021 11.2659C8.87809 10.9545 9.05315 10.5402 9.05315 10.0997C9.05315 9.69083 8.90212 9.30482 8.62638 9.00237L9.48735 8.01925L5.99627 4.56713ZM11.4138 5.81951L12.0871 5.05065C12.2703 5.15023 12.4768 5.20382 12.6921 5.20382C13.0263 5.20382 13.3405 5.07629 13.5767 4.84464L13.5931 4.82864C13.7323 4.69209 13.7323 4.47085 13.5931 4.33444L9.73926 0.557696C9.60014 0.42122 9.37424 0.42122 9.23512 0.557696L9.21879 0.573699C8.98249 0.805212 8.85228 1.11317 8.85228 1.44063C8.85228 1.6516 8.90697 1.85404 9.00858 2.03357L8.23936 2.68053L11.4138 5.81951Z',
      'M3.62256 0.684108L4.31439 0L14 9.57752L13.3082 10.2616L3.62256 0.684108Z',
    ],
  ],
};
