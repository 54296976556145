import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconsModule } from '@epicuro-next/ataraxia/icons';
import { IntersectionModule } from '@epicuro-next/ataraxia/intersection';
import { ProgressiveImageModule } from '@epicuro-next/ataraxia/progressive-image';

import { ImageComponent } from './components/image/image.component';
import { ImageZoomDirective } from './directives/image-zoom.directive';
import { ImageService } from './image.service';

/** Components, pipes and directives that are exported by the module */
const PUBLIC_DECLARATIONS = [ImageComponent, ImageZoomDirective];
@NgModule({
  imports: [
    CommonModule,
    IconsModule,
    ProgressiveImageModule,
    IntersectionModule,
  ],
  declarations: PUBLIC_DECLARATIONS,
  providers: [ImageService],
  exports: PUBLIC_DECLARATIONS,
})
export class ImageModule {}
