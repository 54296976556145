import { Account, Address } from 'graphql-api/gql-api-interfaces';

export function isAddressDeleted(address: Address): boolean {
  return Boolean(address.deletedAtUtc);
}

export function filterDeletedAddress(accounts: Account[]) {
  return accounts.map((account) => {
    return {
      ...account,
      shipToAddresses: account.shipToAddresses.filter(
        (address) => !isAddressDeleted(address),
      ),
    };
  });
}
