import {
  ITrackEventPayload,
  ITrackRequestPayload,
} from '@epicuro-next/models/platform/monitoring';

/**
 * Abstraction of a service for Analytics
 */
export abstract class AbstractAnalyticsService {
  /**
   * Tracks a specific event.
   * @param trackEventPayload
   */
  public abstract trackEvent(trackEventPayload: ITrackEventPayload): void;
  /**
   * Tracks the payload of a request
   * @param trackRequestPayload
   */
  public abstract trackRequestMetrics(
    trackRequestPayload: ITrackRequestPayload,
  ): void;
}
