import { deepmerge } from './imports';

const overwriteMerge = (_: unknown, sourceArray: unknown[], __: unknown) =>
  sourceArray;

interface IMergeOption {
  overrideArrays?: boolean;
}

export function mergeDeep(
  target: object,
  source: object,
  options?: IMergeOption,
) {
  const mergeOption: { [key: string]: unknown } = {};

  if (options?.overrideArrays) {
    mergeOption.arrayMerge = overwriteMerge;
  }

  return deepmerge(target, source, mergeOption);
}
