import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'atx-image-360-icon',
  template: `<svg
    width="47px"
    height="30px"
    viewBox="0 0 47 30"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: Sketch 47.1 (45422) - http://www.bohemiancoding.com/sketch -->
    <defs></defs>
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="W-arrow-v2" transform="translate(-49.000000, -553.000000)">
        <g id="Group-2" transform="translate(50.000000, 549.000000)">
          <text
            id="360°"
            font-family="Roboto-Bold, Roboto"
            font-size="20"
            font-weight="bold"
            fill="#333333"
          >
            <tspan x="5" y="19" i18n="Image360Icon@@image360Icon">360°</tspan>
          </text>
          <g
            id="Arrow"
            transform="translate(22.500000, 29.500000) rotate(-360.000000) translate(-22.500000, -29.500000) translate(0.000000, 24.000000)"
          >
            <path
              d="M1,5 C15.9229843,10.3333333 29.9229843,10.3333333 43,5"
              id="Path-2"
              stroke="#333333"
              stroke-width="2"
            ></path>
            <g
              id="Group"
              stroke-width="1"
              fill-rule="evenodd"
              transform="translate(2.281089, 5.647114) rotate(-330.000000) translate(-2.281089, -5.647114) translate(-1.218911, 1.147114)"
              fill="#333333"
            >
              <rect
                id="Rectangle"
                transform="translate(3.353553, 2.917416) rotate(-45.000000) translate(-3.353553, -2.917416) "
                x="0.853553391"
                y="1.91741583"
                width="5"
                height="2"
              ></rect>
              <rect
                id="Rectangle-Copy"
                transform="translate(3.353553, 5.745843) rotate(-315.000000) translate(-3.353553, -5.745843) "
                x="0.353553391"
                y="4.74584295"
                width="6"
                height="2"
              ></rect>
            </g>
            <g
              id="Group-Copy"
              stroke-width="1"
              fill-rule="evenodd"
              transform="translate(42.545426, 5.252533) rotate(-195.000000) translate(-42.545426, -5.252533) translate(39.045426, 0.752533)"
              fill="#333333"
            >
              <rect
                id="Rectangle"
                transform="translate(3.353553, 2.917416) rotate(-45.000000) translate(-3.353553, -2.917416) "
                x="0.853553391"
                y="1.91741583"
                width="5"
                height="2"
              ></rect>
              <rect
                id="Rectangle-Copy"
                transform="translate(3.353553, 5.745843) rotate(-315.000000) translate(-3.353553, -5.745843) "
                x="0.353553391"
                y="4.74584295"
                width="6"
                height="2"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Image360IconComponent {}
