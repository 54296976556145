import { PULSE_FEATURE_NAME } from '@epicuro-next/constants/rule-engine/pulse-rules';

import { ConfigurationKey, Configurations } from '../models/config.model';
import { ACCOUNTS_FEATURE_NAME } from '../namespaces/accounts.configurations';

import { ConfigurationState } from './configuration.reducer';

/**
 * This is designed to be used in selectors to get rule engine values.
 * The method itself is just picking certaion values in configuration state.
 * It returns the value found or default value.
 *
 * Main purpose of this function is type safety.
 * If you touch the types here, you better have a great reason.
 *
 * K - ConfigurationKeys object type
 * V - Configurations sub state type
 *
 * @param configState - state.configurations
 * @param namespace - rule namespace
 * @param key - key in relevant namespace
 * @param defaultValue - default value if the rule is not present
 */
export function getValueFromConfigState<
  K extends ConfigurationKey,
  V extends Configurations<K> = never,
>(
  configState: ConfigurationState,
  namespace: string,
  key: K,
  defaultValue: V[K],
): V[K] {
  if (!configState) return defaultValue;

  const namespaceConfig = configState[namespace] as V | undefined;
  return namespaceConfig ? namespaceConfig[key] ?? defaultValue : defaultValue;
}

export function isPartOfBaseRules(featureName: string) {
  return [ACCOUNTS_FEATURE_NAME, PULSE_FEATURE_NAME].includes(featureName);
}
