import { RouterNavigationAction, ROUTER_ERROR } from '@ngrx/router-store';
import { Action, ActionReducer } from '@ngrx/store';
import * as Sentry from '@sentry/angular';

import { applyTransformers } from './utils';

const actionTransformers = [extractRouterEventPayloadFromAction];

let actions: Action[] = [];
const MAX_ACTIONS_TO_SEND = 30;
const MAX_ACTION_PAYLOAD_CHARS = 100;
const routerActionMatcher = /ngrx\/router-store/;
const stripActionsWithMatchingRegex = [
  /ngrx\/store-devtools/,
  /\[Configuration\]/,
];

export function resetStoredActions() {
  actions = [];
}

/**
 * NGRX meta reducer to store action information to be sent with sentry events
 * @param reducer
 */
/* tslint:disable:no-any */
export function sentryMetaReducer(
  reducer: ActionReducer<any>,
): ActionReducer<any> {
  /* tslint:disable-next-line:only-arrow-functions */
  return function (state, action) {
    handleNewAction(action);

    return reducer(state, action);
  };
}
/* tslint:enable:no-any */

/**
 * Process and store action information for sentry custom data
 * @param action Action intercepted via sentry meta reducer
 */
function handleNewAction(action: Action) {
  const dontTrack = stripActionsWithMatchingRegex.some((matcher) =>
    matcher.test(action.type),
  );
  if (!dontTrack) {
    actions.unshift(action);
    actions = actions.slice(0, MAX_ACTIONS_TO_SEND);
  }

  checkAndSendSentryEventIfNecessary(action);
}

/**
 * Process actions to be sent with sentry event
 * The function aims to shorten action payload data so that sentry request doesnt get 413
 * It limis stringified action data to be 100 chars long
 */
export function getActionsToAttachSentryEvent() {
  // strip action data and only show type
  return actions.map((action) => {
    const shorthenedActionPayload = JSON.stringify({
      ...applyTransformers(action, actionTransformers),
      type: undefined,
    }).slice(0, MAX_ACTION_PAYLOAD_CHARS);
    return `${action.type}__${shorthenedActionPayload}`;
  });
}

function checkAndSendSentryEventIfNecessary(action: Action) {
  if (action.type === ROUTER_ERROR) {
    const error = new Error(action.type);
    Sentry.captureException(error);
  }
}

function extractRouterEventPayloadFromAction(action: Action) {
  if (!routerActionMatcher.test(action.type)) return action;
  const navigationAction = action as RouterNavigationAction;
  return {
    type: action.type,
    event: navigationAction.payload.event,
  };
}
