import { DateTime } from 'luxon';

export const lastDayOfYearUTC = (): DateTime => {
  // Get the current year
  const currentYear = DateTime.utc().year;

  // Get the last day of the current year in UTC
  const lastDayOfYearUTC = DateTime.utc(currentYear, 12, 31);
  return lastDayOfYearUTC;
};

export const firstDayOfYearUTC = (): DateTime => {
  // Get the current year
  const currentYear = DateTime.utc().year;

  // Get the first day of the current year in UTC
  const firstDayOfYearUTC = DateTime.utc(currentYear, 1, 1);
  return firstDayOfYearUTC;
};
