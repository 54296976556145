import { intervalISODateToDateRange } from '../iso-interval/iso-interval';
import { utcDateTime } from '../utc/utc-date-time';

const formatFullDate = 'dd MMM yyyy';
const formatDayMonth = 'dd MMM';
const formatDay = 'dd';

const formatFullRange = [formatFullDate, formatFullDate];
const formatSameYear = [formatDayMonth, formatFullDate];
const formatSameMonth = [formatDay, formatFullDate];
const formatSameDay = [formatFullDate];

function getFormatForUTCInterval(from: string, to: string) {
  const fromDate = utcDateTime(from);
  const toDate = utcDateTime(to);
  if (fromDate.get('year') !== toDate.get('year')) {
    return formatFullRange;
  }
  if (fromDate.get('month') !== toDate.get('month')) {
    return formatSameYear;
  }
  if (fromDate.get('day') !== toDate.get('day')) {
    return formatSameMonth;
  }
  return formatSameDay;
}

export function formatUTCRange(utcInterval: string) {
  const { from, to } = intervalISODateToDateRange(utcInterval);
  if (!to) {
    return utcDateTime(from).toFormat(formatFullDate);
  }
  const format = getFormatForUTCInterval(from, to);
  const fromDate = utcDateTime(from);
  const toDate = utcDateTime(to);
  if (format.length > 1) {
    return [fromDate.toFormat(format[0]), toDate.toFormat(format[1])].join(
      ' - ',
    );
  }
  return fromDate.toFormat(format[0]);
}
