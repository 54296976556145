export enum EcommerceActionsToTrack {
  SelectVariant = 'selectVariant',
  PageTrack = 'pageTrack',
  SubmitBasketSuccess = 'submitBasketSuccess',
  UpdateBasketItems = 'updateBasketItems',
  SetActiveAccount = 'setActiveAccount',
  LoadFiltersSuccess = 'loadFiltersSuccess',
  Search = 'search',
  RecommendationItemsShown = 'recommendationItemsShown',
  RecommendationItemClicked = 'recommendationItemClicked',
  ViewCart = 'viewCart',
  OpenList = 'openList',
  BeginCheckout = 'beginCheckout',
  Login = 'login',
}
