import { Inject, Injectable, Optional } from '@angular/core';
import {
  ITrackEventPayload,
  ITrackRequestPayload,
} from '@epicuro-next/models/platform/monitoring';

import { AbstractAnalyticsService } from '../abstract/abstract-analytics.service';
import { ANALYTICS_TRANSPORT_TOKEN } from '../tokens';

/**
 * Facade service for analytics hadling. Uses Angular dependency injection to get registered analytics services.
 * Services should extend AbstractAnalyticsService and be provided for `ANALYTICS_TRANSPORT_TOKEN` with `multi: true` option
 */
@Injectable()
export class AnalyticsService extends AbstractAnalyticsService {
  /**
   * Constructor
   * @param transports
   */
  constructor(
    @Optional()
    @Inject(ANALYTICS_TRANSPORT_TOKEN)
    private transports: AbstractAnalyticsService[],
  ) {
    super();

    if (!transports) {
      this.transports = [];
    }
  }

  /**
   * Tracks a specific event.
   * @param trackEventPayload
   */
  public trackEvent(trackEventPayload: ITrackEventPayload): void {
    this.transports.forEach((transport) => {
      try {
        transport.trackEvent(trackEventPayload);
      } catch (e) {}
    });
  }

  /**
   * Tracks the payload of a request
   * @param trackRequestPayload
   */
  public trackRequestMetrics(trackRequestPayload: ITrackRequestPayload): void {
    this.transports.forEach((transport) => {
      try {
        transport.trackRequestMetrics(trackRequestPayload);
      } catch (e) {}
    });
  }
}
