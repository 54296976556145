import { ɵfindEndOfBlock } from '@angular/localize';
import { LocalizeFn } from '@angular/localize/init';

const BLOCK_MARKER = ':';

// simplified localize function for
// https://github.com/angular/angular/blob/33f35b04ef0f32f25624a6be59f8635675e3e131/packages/localize/src/localize/src/localize.ts#L145
// copied and simplified to avoid warning console error
// this is from "@angular/localize": "15.0.3"
export const $simplifiedLocalize: LocalizeFn = (
  messageParts,
  ...expressions
) => {
  let message = stripBlock(messageParts[0], messageParts.raw[0]);
  for (let i = 1; i < messageParts.length; i++) {
    message +=
      expressions[i - 1] + stripBlock(messageParts[i], messageParts.raw[i]);
  }
  return message;
};

function stripBlock(messagePart: string, rawMessagePart: string) {
  return rawMessagePart.charAt(0) === BLOCK_MARKER
    ? messagePart.substring(ɵfindEndOfBlock(messagePart, rawMessagePart) + 1)
    : messagePart;
}
