import { Action, ActionReducer } from '@ngrx/store';

export function resetStateMetaReducer<
  S extends object,
  A extends Action = Action,
>(initialState: S) {
  return (reducer: ActionReducer<S, A>) => {
    return (state: S, action: A): S => {
      if (action.type === '[Session] Logout') {
        return initialState;
      }

      return reducer(state, action);
    };
  };
}
