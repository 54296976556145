import { HttpHeaders } from '@angular/common/http';

/**
 * Extract filename from http response header
 *
 * @param httpHeaders The HTTP response headers
 *
 * @returns an object containing the filename and extension
 */
export function getFilenameFromResponseHeader(httpHeaders: HttpHeaders): {
  name: string;
  extension: string;
} {
  const contentDisposition = httpHeaders.get('content-disposition') ?? '';

  // regex to extract different types of content-disposition
  const regex = /filename[^;=\n]*=(UTF-8(['"]*))?(["'])?([^;\n\r"]*)\3?/;
  const match = regex.exec(contentDisposition);
  const filename = match && match.length > 4 ? match[4] : undefined;

  const filenameSplitted = filename?.split('.');
  const extension = filenameSplitted?.pop();

  return {
    name: filenameSplitted?.join('.') ?? '',
    extension: extension ?? '',
  };
}
