export type EntityTransformer<T> = (value: T) => T;

export function applyTransformers<T>(
  entity: T,
  transformers: EntityTransformer<T>[],
): T {
  return transformers.reduce((prev, curr) => {
    try {
      return curr(prev);
    } catch (e) {
      return prev;
    }
  }, entity);
}
