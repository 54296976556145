enum NewsfeedType {
  Spotlight = 'spotlight',
  WelcomePromotion = 'welcomePromotion',
  LandingPage = 'landingPage',
}

export function getListFeedIdPropertyByFeedType(feedType: NewsfeedType) {
  switch (feedType) {
    case NewsfeedType.LandingPage:
      return 'landingPageNewsfeedId';
    case NewsfeedType.WelcomePromotion:
      return 'welcomePromotionNewsfeedId';
    default:
      return 'newsfeedId';
  }
}
