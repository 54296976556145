import { atxIsMobile$ } from '@epicuro-next/ataraxia/breakpoint';
import { EnvironmentSettings } from '@epicuro-next/platform/environment';
import * as Sentry from '@sentry/angular';
import * as SentryBrowser from '@sentry/browser';
import { Dedupe } from '@sentry/integrations';
import { distinctUntilChanged } from 'rxjs/operators';

import { beforeSend } from './before-send';
import { denyUrls, ignoreErrors } from './constants';
import { beforeNavigate } from './performance-utils';

const VERSION_FILE_PATH = '/version.json';

export async function initSentry(
  dsn: string,
  environment: EnvironmentSettings,
) {
  const { sentryTraceSampleRate } = environment;
  const tracesSampleRate = parseFloat(
    (sentryTraceSampleRate || 0.1)?.toString(),
  );

  Sentry.init({
    release: await getReleaseVersion(),
    dsn,
    autoSessionTracking: true,
    integrations: [
      new SentryBrowser.BrowserTracing({
        beforeNavigate,
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Dedupe(),
    ],
    beforeSend,
    // breadcrumb data for errors. Needs to be small enough to not throw 413
    // Beware that it doesn't act same in localhost and prod builds
    maxBreadcrumbs: 10,
    // performance monitoring sample percentage (0-1)
    tracesSampleRate,
    ignoreErrors,
    denyUrls,
  });

  setTags(environment);
}

async function getReleaseVersion() {
  let release: string | undefined;
  try {
    const res = await readTextFile(VERSION_FILE_PATH);
    const versionData = JSON.parse(res);
    release = `${versionData.branch}`;
  } catch (e) {
    console.error("Couldn't read version information");
  }
  return release;
}

function readTextFile(filePath: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const rawFile = new XMLHttpRequest();
    rawFile.overrideMimeType('application/json');
    rawFile.open('GET', filePath, true);
    rawFile.onreadystatechange = () => {
      if (rawFile.readyState === 4 && rawFile.status === 200) {
        resolve(rawFile.responseText);
      } else if (rawFile.readyState === 4 && rawFile.status !== 200) {
        reject(`${filePath} not found`);
      }
    };
    rawFile.send(null);
  });
}

function setTags(environment: EnvironmentSettings) {
  const { tenant, env } = environment;

  if (tenant) Sentry.setTag('tenant', tenant);
  if (env) Sentry.setTag('env', env);

  atxIsMobile$.pipe(distinctUntilChanged()).subscribe((isMobile) => {
    Sentry.setTag('platform', isMobile ? 'pwa' : 'web');
  });
}
