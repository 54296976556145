import { utcDateTime } from './utc-date-time';

export function getUTCMonthsRange(
  startDate: string,
  endDate: string,
): string[] {
  if (startDate > endDate) {
    [startDate, endDate] = [endDate, startDate];
  }

  const todayUtc = utcDateTime(new Date());
  const startDateUtc = utcDateTime(startDate);
  const start = todayUtc > startDateUtc ? todayUtc : startDateUtc;
  const end = utcDateTime(endDate);

  const maxAvailableEndDate = start
    .startOf('month')
    .set({ hour: 12 })
    .plus({ months: 12 });
  const resultEndDate = end > maxAvailableEndDate ? maxAvailableEndDate : end;

  const dateStrings = new Set<string>();

  for (
    let date = start.startOf('month').set({ hour: 12 });
    date <= resultEndDate;
    date = date.plus({ months: 1 })
  ) {
    dateStrings.add(
      date.toISO({
        suppressMilliseconds: true,
      }),
    );
  }

  return [...dateStrings];
}
