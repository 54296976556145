import {
  ConnectedPosition,
  HorizontalConnectionPos,
  OriginConnectionPosition,
  OverlayConnectionPosition,
  VerticalConnectionPos,
} from '@angular/cdk/overlay';

export enum Position {
  TOP = 'top',
  BOTTOM = 'bottom',
  CENTER = 'center',
  START = 'start',
  END = 'end',
}

export enum Placement {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
}

/** Component placement configuration */
interface PlacementConfig {
  /** Top placement */
  top: ConnectedPosition;

  /** Bottom placement */
  bottom: ConnectedPosition;

  /** Left placement */
  left: ConnectedPosition;

  /** Right placement */
  right: ConnectedPosition;

  [Placement.TOP_LEFT]: ConnectedPosition;

  [Placement.TOP_RIGHT]: ConnectedPosition;

  [Placement.BOTTOM_LEFT]: ConnectedPosition;

  [Placement.BOTTOM_RIGHT]: ConnectedPosition;
}

/** Placements config option values */
export const PLACEMENT_CONFIG: PlacementConfig = {
  top: {
    originX: Position.CENTER,
    originY: Position.TOP,
    overlayX: Position.CENTER,
    overlayY: Position.BOTTOM,
  },
  bottom: {
    originX: Position.CENTER,
    originY: Position.BOTTOM,
    overlayX: Position.CENTER,
    overlayY: Position.TOP,
  },
  left: {
    originX: Position.START,
    originY: Position.CENTER,
    overlayX: Position.END,
    overlayY: Position.CENTER,
  },
  right: {
    originX: Position.END,
    originY: Position.CENTER,
    overlayX: Position.START,
    overlayY: Position.CENTER,
  },
  [Placement.TOP_LEFT]: {
    originX: Position.START,
    originY: Position.TOP,
    overlayX: Position.START,
    overlayY: Position.BOTTOM,
  },
  [Placement.TOP_RIGHT]: {
    originX: Position.END,
    originY: Position.TOP,
    overlayX: Position.END,
    overlayY: Position.BOTTOM,
  },
  [Placement.BOTTOM_LEFT]: {
    originX: Position.START,
    originY: Position.BOTTOM,
    overlayX: Position.START,
    overlayY: Position.TOP,
  },
  [Placement.BOTTOM_RIGHT]: {
    originX: Position.END,
    originY: Position.BOTTOM,
    overlayX: Position.END,
    overlayY: Position.TOP,
  },
};

/**
 * Utility method to get placement config and fallback placement config
 */
// tslint:disable-next-line: cognitive-complexity
export function getPlacementPairs(placement: Placement): ConnectedPosition[] {
  let fallbackPlacement: Placement = Placement.TOP;

  if (placement === Placement.TOP || placement === Placement.BOTTOM) {
    fallbackPlacement =
      placement === Placement.TOP ? Placement.BOTTOM : Placement.TOP;
  }

  if (placement === Placement.LEFT || placement === Placement.RIGHT) {
    fallbackPlacement =
      placement === Placement.LEFT ? Placement.RIGHT : Placement.LEFT;
  }

  if (placement === Placement.TOP_LEFT || placement === Placement.TOP_RIGHT) {
    fallbackPlacement =
      placement === Placement.TOP_LEFT
        ? Placement.BOTTOM_LEFT
        : Placement.BOTTOM_RIGHT;
  }

  if (
    placement === Placement.BOTTOM_LEFT ||
    placement === Placement.BOTTOM_RIGHT
  ) {
    fallbackPlacement =
      placement === Placement.BOTTOM_LEFT
        ? Placement.TOP_LEFT
        : Placement.TOP_RIGHT;
  }

  return [PLACEMENT_CONFIG[placement], PLACEMENT_CONFIG[fallbackPlacement]];
}

/**
 * Returns the origin position and a fallback position based on the user's position preference.
 * The fallback position is the inverse of the origin (e.g. `'below' -> 'above'`).
 * @param placement Placement of the component.
 */
export function getOriginPosition(
  placement: Placement = Placement.BOTTOM,
): { main: OriginConnectionPosition; fallback: OriginConnectionPosition } {
  let originPosition: OriginConnectionPosition;

  const p = placement.split('-');
  let originX: HorizontalConnectionPos = Position.CENTER;
  if (p[1]) {
    originX = p[1] === Placement.LEFT ? Position.START : Position.END;
  }

  if (p[0] === Placement.TOP || p[0] === Placement.BOTTOM) {
    originPosition = { originX, originY: p[0] };
  } else if (p[0] === Placement.LEFT) {
    originPosition = { originX: Position.START, originY: Position.CENTER };
  } else {
    originPosition = { originX: Position.END, originY: Position.CENTER };
  }

  const { x, y } = invertPosition(
    placement,
    originPosition.originX,
    originPosition.originY,
  );

  return {
    main: originPosition,
    fallback: { originX: x, originY: y },
  };
}

/**
 * Returns the overlay position and a fallback position based on the user's preference.
 * The fallback position is the inverse of the origin (e.g. `'below' -> 'above'`).
 * @param placement Placement of Component.
 */
export function getOverlayPosition(
  placement: Placement = Placement.BOTTOM,
): { main: OverlayConnectionPosition; fallback: OverlayConnectionPosition } {
  let overlayPosition: OverlayConnectionPosition;

  const p = placement.split('-');
  let overlayX: HorizontalConnectionPos = Position.CENTER;
  if (p[1]) {
    overlayX = p[1] === Placement.LEFT ? Position.START : Position.END;
  }

  if (p[0] === Placement.TOP) {
    overlayPosition = { overlayX, overlayY: Position.BOTTOM };
  } else if (p[0] === Placement.BOTTOM) {
    overlayPosition = { overlayX, overlayY: Position.TOP };
  } else if (p[0] === Placement.LEFT) {
    overlayPosition = { overlayX: Position.END, overlayY: Position.CENTER };
  } else {
    overlayPosition = { overlayX: Position.START, overlayY: Position.CENTER };
  }

  const { x, y } = invertPosition(
    placement,
    overlayPosition.overlayX,
    overlayPosition.overlayY,
  );

  return {
    main: overlayPosition,
    fallback: { overlayX: x, overlayY: y },
  };
}

/**
 * Inverts an overlay position.
 */
export function invertPosition(
  placement: Placement,
  x: HorizontalConnectionPos,
  y: VerticalConnectionPos,
) {
  if (placement === Placement.TOP || placement === Placement.BOTTOM) {
    if (y === Position.TOP) {
      y = Position.BOTTOM;
    } else if (y === Position.BOTTOM) {
      y = Position.TOP;
    }
  } else {
    if (x === Position.END) {
      x = Position.START;
    } else if (x === Position.START) {
      x = Position.END;
    }
  }

  return { x, y };
}
