export function objectKeysToLowercase<T extends object>(obj: T): T {
  if (typeof obj !== 'object') {
    return {} as T;
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key.toLowerCase()]: value,
    };
  }, {} as T);
}
