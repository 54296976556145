import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  EnvironmentModule,
  EnvironmentSettings,
  ENVIRONMENT_SETTINGS,
} from '@epicuro-next/platform/environment';
import { isString } from '@epicuro-next/utilities';

import { RuleEngine } from './rule-engine.service';
import {
  RULE_ENGINE_API,
  RULE_ENGINE_V1_API,
  RULE_ENGINE_V2_API,
} from './tokens';

@NgModule({
  imports: [CommonModule, EnvironmentModule],
  providers: [
    {
      provide: RULE_ENGINE_API,
      useFactory: (
        settings: EnvironmentSettings,
        urlV1: string,
        urlV2: string,
      ) => {
        const enableV2RuleEngine = isString(settings.enableV2RuleEngine)
          ? JSON.parse(settings.enableV2RuleEngine)
          : settings.enableV2RuleEngine;
        return enableV2RuleEngine ? urlV2 : urlV1;
      },
      deps: [ENVIRONMENT_SETTINGS, RULE_ENGINE_V1_API, RULE_ENGINE_V2_API],
    },
    RuleEngine,
  ],
})
export class RuleEngineModule {}
