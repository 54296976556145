import { getValue } from './imports';

export const arrayIsEmpty = <T>(array: T[]): boolean => {
  return array.length === 0;
};

export const arrayIsNotEmpty = <T>(array: T[]): boolean => !arrayIsEmpty(array);

// to be used as array.filter(unique)
// works well only with primitive types
// tslint:disable-next-line:no-any
export function unique<T extends any>(value: T, index: number, self: T[]) {
  return self.indexOf(value) === index;
}

export function uniqueBy<T>(
  array: T[],
  key: keyof T,
  ...keys: (keyof T)[]
): T[] {
  const allKeys = [key, ...keys];

  function formatter(item: T) {
    return allKeys.map((k) => item[k]).join('_');
  }
  return filterArray(array, formatter);
}

export function uniqueByPropertyPath<T extends object>(
  array: T[],
  key: string,
  ...keys: string[]
): T[] {
  const allKeys = [key, ...keys];

  function formatter(item: T) {
    return allKeys.map((k) => getValue(item, k)).join('_');
  }

  return filterArray(array, formatter);
}

export function filterArray<T>(array: T[], formatter: (i: T) => string): T[] {
  const seen = new Set<string>();
  return array.filter((item) => {
    const formatted = formatter(item);

    if (seen.has(formatted)) {
      return false;
    }

    seen.add(formatted);
    return true;
  });
}

export function moveArrayElement<T>(
  array: T[],
  fromIndex: number,
  toIndex: number,
): T[] {
  // Check if fromIndex and toIndex are within bounds of the array
  if (
    fromIndex < 0 ||
    fromIndex >= array.length ||
    toIndex < 0 ||
    toIndex >= array.length
  ) {
    console.error('Invalid indices');
    return array;
  }

  const reorderedArray = [...array];

  // Remove the element from the fromIndex
  const [element] = reorderedArray.splice(fromIndex, 1);
  // Insert the element at the new index
  reorderedArray.splice(toIndex, 0, element);

  return reorderedArray;
}

export type UnknownDimensionArray<T> = UnknownDimensionArray<T>[];
