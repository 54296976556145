import { TransactionContext } from '@sentry/types';

export function beforeNavigate(context: TransactionContext) {
  return {
    ...context,
    name: transformPath(context.name ?? location.pathname),
  };
}

function transformPath(path: string) {
  return path
    .replace(/\/[a-f0-9-]{36}/g, '/<id>')
    .replace(/\/[a-f0-9]{32}/g, '/<id>')
    .replace(/\/(.+?)\(overlay\:cs\/(.+?)\)/, '/$1/config-strip/<params>')
    .replace(/\/\d+/g, '/<digits>');
}
