import { BROWSER_STORAGE_SERVICE } from '@epicuro-next/platform/storage';
import { mergeDeep, pick } from '@epicuro-next/utilities';
import { Action, ActionReducer } from '@ngrx/store';

export function storageMetaReducer<S extends object, A extends Action = Action>(
  saveKeys: string[],
  browserStorageKey: string,
  storageService: BROWSER_STORAGE_SERVICE,
) {
  let onInit = true; // after load/refresh…

  return (reducer: ActionReducer<S, A>) => {
    return (state: S, action: A): S => {
      // get to the nextState.
      const nextState = reducer(state, action);

      // init the application state.
      if (onInit) {
        onInit = false;
        const savedState = storageService.get(browserStorageKey);

        return mergeDeep(nextState ?? {}, savedState ?? {}, {
          overrideArrays: true,
        }) as S;
      }

      // save the next state to the application storage.
      // better to use pick with PropertyPath feature to save nested properties
      // if empty array is provided for saveKeys, then all state is cached
      const stateToSave = saveKeys.length
        ? pick(nextState, saveKeys)
        : nextState;

      storageService.set(browserStorageKey, stateToSave);

      return nextState;
    };
  };
}
