import { Filter, Maybe, SearchQuery } from 'graphql-api/gql-api-interfaces';

export const isSameFacetFilter = (
  query: SearchQuery,
  previousSearchQuery?: Maybe<SearchQuery>,
): ((defaultFacetFilter: Filter) => boolean) => {
  const previousSearchQueryMap = new Map(
    (previousSearchQuery?.f?.split(',') ?? [':']).map((prevFilterPair) =>
      prevFilterPair.split(':'),
    ) as [[string, string]],
  );

  const currentSearchQueryMap = new Map(
    (query?.f?.split(',') ?? [':']).map((currFilterPair) =>
      currFilterPair.split(':'),
    ) as [[string, string]],
  );

  return (defaultFacetFilter: Filter): boolean => {
    let sameFacetFilterChange = false;
    if (
      previousSearchQueryMap.size > 0 &&
      currentSearchQueryMap.size > 0 &&
      !!previousSearchQueryMap.get(defaultFacetFilter.fieldId) &&
      !!currentSearchQueryMap.get(defaultFacetFilter.fieldId) &&
      previousSearchQueryMap.get(defaultFacetFilter.fieldId) !==
        currentSearchQueryMap.get(defaultFacetFilter.fieldId)
    ) {
      sameFacetFilterChange = true;
    }

    return sameFacetFilterChange;
  };
};
