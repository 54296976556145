import { CommonModule, DOCUMENT } from '@angular/common';
import { ModuleWithProviders, NgModule, RendererFactory2 } from '@angular/core';
import {
  EnvironmentSettings,
  ENVIRONMENT_SETTINGS,
} from '@epicuro-next/platform/environment';
import { Angulartics2Module } from 'angulartics2';

import { GtmDirective } from './directives/gtm.directive';
import { GCRConfigFactory } from './factories/gcr-config-factory';
import { GTagConfigFactory } from './factories/gtag-config-factory';
import { GTMConfigFactory } from './factories/gtm-config-factory';
import { GTAGConfig } from './interfaces/gtag-config';
import { GTMConfig } from './interfaces/gtm-config';
import { AbstractTrackClient } from './service/abstract-track-client';
import { Angulartics } from './service/angulartics.service';
import { GCRService } from './service/gcr.service';
import { GTagService } from './service/gtag.service';
import { GTMService } from './service/gtm.service';
import { TrackingService } from './service/tracking.service';
import {
  GCR_CONFIG_TOKEN,
  GTAG_CONFIG_TOKEN,
  GTM_GONFIG_TOKEN,
} from './tokens';

@NgModule({
  imports: [CommonModule, Angulartics2Module],
  providers: [
    Angulartics,
    TrackingService,
    {
      provide: GTM_GONFIG_TOKEN,
      useFactory: GTMConfigFactory,
      deps: [ENVIRONMENT_SETTINGS],
    },
    {
      provide: GTAG_CONFIG_TOKEN,
      useFactory: GTagConfigFactory,
      deps: [ENVIRONMENT_SETTINGS],
    },
    {
      provide: GCR_CONFIG_TOKEN,
      useFactory: GCRConfigFactory,
      deps: [ENVIRONMENT_SETTINGS],
    },
    GCRService,
  ],
  declarations: [GtmDirective],
  exports: [GtmDirective],
})
export class TrackingModule {
  public static forRoot(): ModuleWithProviders<TrackingModule> {
    return {
      ngModule: TrackingModule,
      providers: [
        {
          provide: AbstractTrackClient,
          useFactory: (
            document: Document,
            angulartics: Angulartics,
            gtmConfig: GTMConfig,
            gtagConfig: GTAGConfig[] | null,
            rendererFactory2: RendererFactory2,
            environmentSettings: EnvironmentSettings,
          ) =>
            gtagConfig
              ? new GTagService(
                  rendererFactory2,
                  document,
                  gtagConfig,
                  environmentSettings,
                )
              : new GTMService(
                  rendererFactory2,
                  document,
                  angulartics,
                  gtmConfig,
                ),
          deps: [
            DOCUMENT,
            Angulartics,
            GTM_GONFIG_TOKEN,
            GTAG_CONFIG_TOKEN,
            RendererFactory2,
            ENVIRONMENT_SETTINGS,
          ],
        },
      ],
    };
  }
}
