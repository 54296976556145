import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, RendererFactory2 } from '@angular/core';
import {
  EnvironmentSettings,
  ENVIRONMENT_SETTINGS,
} from '@epicuro-next/platform/environment';

import { GTAGConfig } from '../interfaces/gtag-config';
import { GATrackType, TrackingConfig } from '../models/tracking-config';
import { GTAG_CONFIG_TOKEN } from '../tokens';

import { AbstractTrackClient } from './abstract-track-client';

@Injectable()
export class GTagService extends AbstractTrackClient {
  private initialized = false;

  constructor(
    protected renderFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(GTAG_CONFIG_TOKEN) private settings: GTAGConfig[] | null,
    @Inject(ENVIRONMENT_SETTINGS) private environment: EnvironmentSettings,
  ) {
    super(renderFactory);

    this.initialize();
  }

  public startTracking() {
    this.trackingStarted = true;
  }

  public sendTrackEvent(payload: TrackingConfig): void {
    if (
      payload.sendToGA &&
      this.isSendToAvailableAsGroup(payload.gaPayload?.send_to)
    ) {
      this.pushData(payload);
    }
  }

  private isSendToAvailableAsGroup(group: string | undefined) {
    // if no group is specified send it to all GA4 containers
    if (!group) {
      return true;
    }

    // Check if group is defined in config, else gtag will send it to all containers. This code will prevent that.
    return this.settings?.some(
      (gtaSettings: GTAGConfig) =>
        gtaSettings.group?.toLowerCase() === group.toLowerCase(),
    );
  }

  private pushData(payload: TrackingConfig) {
    // tslint:disable-next-line:no-any
    const gtag = (window as any).gtag;
    const enableDebug =
      this.environment?.env === 'test' || this.environment?.env === 'acc';
    const payLoad = enableDebug
      ? { ...payload.gaPayload, debug_mode: true }
      : payload.gaPayload;

    if (gtag !== undefined) {
      switch (payload.trackType) {
        case GATrackType.Event:
          gtag(payload.trackType, payload.eventName, payLoad);
          break;
        case GATrackType.Config:
        case GATrackType.Set:
          gtag(payload.trackType, payLoad);
          break;
        default:
          console.error('Please provide track type in config');
      }
    }
  }

  private initialize() {
    if (this.initialized || !this.settings) {
      return;
    }

    this.appendToHeadScript();
    this.initialized = true;
  }

  private appendToHeadScript() {
    const gtagScript = this.renderer.createElement('script');
    gtagScript.setAttribute('async', '');
    gtagScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js');

    const gtagConfigScript = this.renderer.createElement('script');
    gtagConfigScript.text =
      ' window.dataLayer = window.dataLayer || [];\n' +
      ' function gtag(){dataLayer.push(arguments);}\n' +
      " gtag('js', new Date());\n";

    this.settings?.forEach(
      (value) =>
        (gtagConfigScript.text += ` gtag('config', '${
          value.measurementId
        }', { send_page_view: false, ${
          value.group ? `groups: '${value.group}'` : ''
        } });\n`),
    );

    this.renderer.appendChild(this.document.head, gtagScript);
    this.renderer.appendChild(this.document.head, gtagConfigScript);
  }
}
