export const isFilterOfType = (id: string) => (filter: { id: string }) =>
  filter?.id.toLowerCase() === id.toLowerCase();

// copy from gql
enum FilterType {
  /** Type for date range. */
  DateRange = 'DateRange',
  Group = 'Group',
  Mapped = 'Mapped',
  /** Type for price range. */
  Range = 'Range',
  Value = 'Value',
}

export const isSearchFilterOfType =
  (type: FilterType) => (filter?: { type: FilterType }) =>
    filter?.type === type;
export const isDateRangeTypeFilter = isSearchFilterOfType(FilterType.DateRange);
export const isRangeTypeFilter = isSearchFilterOfType(FilterType.Range);
