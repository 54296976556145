import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Output,
  Renderer2,
} from '@angular/core';

export const PREVIEW_QUALITY = 10;

@Directive({
  selector: '[atxProgressiveImage]',
})
export class ProgressiveImageDirective implements AfterViewInit, OnChanges {
  @Input() public imgUrl: string;
  @Input() public loadOriginal = true;
  @Output() public imageLoaded = new EventEmitter();

  constructor(
    private elementRef: ElementRef,
    private zone: NgZone,
    private renderer: Renderer2,
  ) {}

  public ngAfterViewInit() {
    this.preload();
  }

  public ngOnChanges() {
    if (this.elementRef.nativeElement) {
      this.preload();
    }
  }

  public preload() {
    if (this.imgUrl) {
      const element = this.elementRef.nativeElement;
      const imgUrl =
        this.imgUrl + `&quality=${PREVIEW_QUALITY}&fastscale=true&speed=1`;

      this.renderer.setAttribute(element, 'src', imgUrl);
      this.renderer.setStyle(element, 'filter', 'blur(5px)');
      if (this.loadOriginal) {
        const origImg = new Image();
        origImg.src = this.imgUrl;

        if (origImg.complete) {
          this.onImageLoaded();
        }
        this.zone.runOutsideAngular(() => {
          origImg.onload = () => {
            this.onImageLoaded();
          };
        });
      }
    }
  }

  private onImageLoaded() {
    const element = this.elementRef.nativeElement;
    this.renderer.setAttribute(element, 'src', this.imgUrl);
    this.renderer.removeStyle(element, 'filter');
    this.imageLoaded.emit();
  }
}
