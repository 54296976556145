import { InjectionToken } from '@angular/core';

/** rule engine v1 api Injection Token */
export const RULE_ENGINE_V1_API: InjectionToken<string> = new InjectionToken(
  'RULE_ENGINE_V1_API',
);

/** rule engine v2 api Injection Token */
export const RULE_ENGINE_V2_API: InjectionToken<string> = new InjectionToken(
  'RULE_ENGINE_V2_API',
);

/** rule engine used api Injection Token */
export const RULE_ENGINE_API: InjectionToken<string> = new InjectionToken(
  'RULE_ENGINE_API',
);
