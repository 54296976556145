import { Configurations } from '../models/config.model';

export const NAMESPACE_CONTENT = 'content';

export enum ContentConfigurationKeys {
  showPlayground = 'show-playground',
}

export interface ContentConfigurations
  extends Configurations<ContentConfigurationKeys> {
  [ContentConfigurationKeys.showPlayground]: boolean;
}
