import { Injectable } from '@angular/core';
import {
  AbstractLoggerService,
  IErrorLog,
  IMessageLog,
} from '@epicuro-next/models/platform/monitoring';
import {
  IExceptionTelemetry,
  ITraceTelemetry,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';

import { AppInsightsService } from './app-insights.service';

/**
 * Implementation of AbstractLoggerService for Microsift Application insights
 */
@Injectable()
export class AppInsightsLoggerService extends AbstractLoggerService {
  /**
   * Constructor
   * @param appInsightsService
   */
  constructor(private appInsightsService: AppInsightsService) {
    super();
  }

  /**
   * Logs an exception
   * @param errorLog
   */
  public exception(errorLog: IErrorLog): void {
    const payload: IExceptionTelemetry = {
      exception: errorLog.error,
      severityLevel: errorLog.severity || SeverityLevel.Error,
    };

    if (errorLog.tags) {
      payload.properties = {
        ...payload.properties,
        ...errorLog.tags,
      };
    }

    if (errorLog.extra) {
      payload.properties = {
        ...payload.properties,
        ...errorLog.extra,
      };
    }

    this.appInsightsService.instance.trackException(payload);
  }
  /**
   * Logs a message
   * @param messageLog
   */
  public message(messageLog: IMessageLog): void {
    const payload: ITraceTelemetry = {
      message: messageLog.message,
    };

    if (messageLog.severity) {
      payload.severityLevel = messageLog.severity;
    }

    if (messageLog.tags) {
      payload.properties = {
        ...payload.properties,
        ...messageLog.tags,
      };
    }

    if (messageLog.extra) {
      payload.properties = {
        ...payload.properties,
        ...messageLog.extra,
      };
    }

    this.appInsightsService.instance.trackTrace(payload);
  }
}
