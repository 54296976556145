import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[atxVisible]',
  exportAs: 'atxVisible',
})
export class VisibleDirective implements OnDestroy, OnChanges, AfterViewInit {
  // tslint:disable-next-line:no-input-rename
  @Input('visibleEnabled')
  public enabled = true;

  @Input()
  public once = false;

  @Input()
  public threshold: number | number[] = 0.1;

  @Output()
  public visible = new EventEmitter<boolean>();

  private observer: IntersectionObserver | null;

  constructor(private elementRef: ElementRef) {}

  public ngAfterViewInit(): void {
    if (!this.enabled) {
      return;
    }

    this.setUp();
  }

  public ngOnDestroy(): void {
    this.cleanUp();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const enabledChange: SimpleChange = changes.enabled;

    if (
      enabledChange?.isFirstChange() ||
      enabledChange?.currentValue === enabledChange?.previousValue
    ) {
      return;
    }

    if (!enabledChange?.currentValue) {
      this.cleanUp();

      return;
    }

    this.setUp();
  }

  private setUp() {
    this.observer = new IntersectionObserver(
      (entries) => {
        // isIntersecting is true when element and viewport are overlapping
        // isIntersecting is false when element and viewport don't overlap
        if (entries[0].isIntersecting === true) {
          this.visible.emit(true);

          if (this.once) {
            this.cleanUp();
          }

          return;
        }
        this.visible.emit(false);
      },
      { threshold: this.threshold },
    );
    this.observer.observe(this.elementRef.nativeElement);
  }

  private cleanUp() {
    if (!this.observer) {
      return;
    }

    this.observer.unobserve(this.elementRef.nativeElement);
    this.observer.disconnect();
    this.observer = null;
  }
}
