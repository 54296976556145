import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { Snippet } from '@microsoft/applicationinsights-web';

import {
  ANALYTICS_TRANSPORT_TOKEN,
  CONTEXT_TRANSPORT_TOKEN,
  LOGGER_TRANSPORT_TOKEN,
} from '../../tokens';

import { AppInsightsAnalyticsService } from './app-insights-analytics.service';
import { AppInsightsContextService } from './app-insights-context.service';
import { AppInsightsLoggerService } from './app-insights-logger.service';
import { AppInsightsService } from './app-insights.service';
import { APPINSIGHTS_CONFIG } from './tokens';

/**
 * Returns the configuration required by App Insights
 */
export function getAppInsightsSettings(): Snippet {
  return {
    config: {
      instrumentationKey: 'test',
      disableAjaxTracking: true,
    },
  };
}

/**
 * Angular module configured with App Insights configured for logs and analytics
 */
@NgModule({
  imports: [CommonModule],
  providers: [
    AppInsightsService,
    {
      provide: ANALYTICS_TRANSPORT_TOKEN,
      useClass: AppInsightsAnalyticsService,
      multi: true,
    },
    {
      provide: CONTEXT_TRANSPORT_TOKEN,
      useClass: AppInsightsContextService,
      multi: true,
    },
    {
      provide: LOGGER_TRANSPORT_TOKEN,
      useClass: AppInsightsLoggerService,
      multi: true,
    },
  ],
})
export class AppInsightsModule {
  /**
   * Initializes the injection token APPINSIGHTS_CONFIG
   */
  public static forRoot(): ModuleWithProviders<AppInsightsModule> {
    return {
      ngModule: AppInsightsModule,
      providers: [
        {
          provide: APPINSIGHTS_CONFIG,
          useFactory: getAppInsightsSettings,
        },
      ],
    };
  }
}
