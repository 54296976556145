import { Action, createReducer, on } from '@ngrx/store';

import {
  ACCOUNT_ID,
  APP_VERSION,
  BASKET_ID,
  SessionState,
  SITE_ID,
  SUB_SITE_ID,
} from './model';
import * as sessionActions from './session.actions';

/**
 * State slice key
 */
export const sessionFeatureKey = 'session';

/**
 * Initial state for the session
 */
export const initialState: SessionState = {
  [SITE_ID]: null,
  [SUB_SITE_ID]: null,
  [ACCOUNT_ID]: null,
  [BASKET_ID]: null,
  [APP_VERSION]: null,
};

/**
 * Reducer for session-related actions
 */
const sessionReducer = createReducer(
  initialState,
  on(sessionActions.patchSession, (state, action) => {
    const newState = { ...state };
    Object.keys(action.session).forEach(
      (key) => (newState[key] = action.session[key] ?? null),
    );
    return newState;
  }),
);

/**
 * Exported function because of Angular AoT
 */
export function reducer(state: SessionState | undefined, action: Action) {
  return sessionReducer(state, action);
}

/**
 * Site id slice selector
 */
export const getSiteId = (state: SessionState) => state.siteId;
/**
 * Sub site id slice selector
 */
export const getSubSiteId = (state: SessionState) => state[SUB_SITE_ID];
/**
 * Account id slice selector
 */
export const getAccountId = (state: SessionState) => state.accountId;

/** get version id */
export const getAppVersion = (state: SessionState) => state[APP_VERSION];
