import { Configurations } from '../models/config.model';

export const NAMESPACE_SEARCH_SUGGEST = 'search-suggest';

export enum SearchSuggestConfigurationKeys {
  SuggestedProductsCount = 'suggested-products-count',
  ApplyFiltersOnRequest = 'apply-filters-on-request',
  // if set to true it is better to use together with ApplyFiltersOnRequest rule (true)
  // as suggest result which depends on current filters decrease possibility no result for search after applying new suggest filter
  SkipResetOtherFilters = 'skip-reset-other-filters',
}

export interface SearchSuggestConfigurations
  extends Configurations<SearchSuggestConfigurationKeys> {
  /**
   * Whether to show the bulk add to list button
   */
  [SearchSuggestConfigurationKeys.SuggestedProductsCount]: number;
  [SearchSuggestConfigurationKeys.ApplyFiltersOnRequest]: boolean;
  [SearchSuggestConfigurationKeys.SkipResetOtherFilters]: boolean;
}

export const searchSuggestConfigurationsDefaultValues: SearchSuggestConfigurations =
  {
    [SearchSuggestConfigurationKeys.SuggestedProductsCount]: 5,
    [SearchSuggestConfigurationKeys.ApplyFiltersOnRequest]: false,
    [SearchSuggestConfigurationKeys.SkipResetOtherFilters]: false,
  };
