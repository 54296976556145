export enum ATX_DEVICE_SIZE {
  XS = 'XS',
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
  XL = 'XL',
  XXL = 'XXL',
}

export const ATX_BREAKPOINT_WIDTH: { [key in ATX_DEVICE_SIZE]: number } = {
  XS: 0,
  SM: 576,
  MD: 769,
  LG: 992,
  XL: 1200,
  XXL: 1880,
};

export const ATX_BREAKPOINT_MEDIA: { [key in ATX_DEVICE_SIZE]: string } = {
  XS: `(max-width: ${ATX_BREAKPOINT_WIDTH.SM - 1}px)`,
  SM: `(min-width: ${ATX_BREAKPOINT_WIDTH.SM}px)`,
  MD: `(min-width: ${ATX_BREAKPOINT_WIDTH.MD}px)`,
  LG: `(min-width: ${ATX_BREAKPOINT_WIDTH.LG}px)`,
  XL: `(min-width: ${ATX_BREAKPOINT_WIDTH.XL}px)`,
  XXL: `(min-width: ${ATX_BREAKPOINT_WIDTH.XXL}px)`,
};
