import {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core';

/** delivered icon */
export const faDelivered: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'delivered' as IconName,
  icon: [
    16,
    16,
    [],
    '',
    'M16,8 L14,4 L11,4 L11,2 C11,1.45 10.55,1 10,1 L1,1 C0.45,1 0,1.45 0,2 L0,10 L1,11 L2.268,11 C2.098,11.294 2,11.636 2,12 C2,13.105 2.895,14 4,14 C5.105,14 6,13.105 6,12 C6,11.636 5.902,11.294 5.732,11 L11.268,11 C11.098,11.294 11,11.636 11,12 C11,13.105 11.895,14 13,14 C14.105,14 15,13.105 15,12 C15,11.636 14.902,11.294 14.732,11 L16,11 L16,8 Z M8.3,3 L9,3.7826087 L4.33333333,9 L2,6.39130435 L2.7,5.60869565 L4.33333333,7.43478261 L8.3,3 Z M13.073,5 L14.573,8 L11,8 L11,5 L13.073,5 Z',
  ],
};
