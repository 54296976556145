import { Configurations } from '../models/config.model';

export const NAMESPACE_NAVIGATION = 'navigation';

export enum NavigationConfigurationKeys {
  HomeAndBrowseEnabled = 'home-and-browse-enabled',
  OffboardingEnabled = 'is-offboarding-enabled',
}

export interface NavigationConfigurations
  extends Configurations<NavigationConfigurationKeys> {
  [NavigationConfigurationKeys.HomeAndBrowseEnabled]: boolean;
  [NavigationConfigurationKeys.OffboardingEnabled]: boolean;
}
