import { isNullish } from './nullish-check';

export const isMultipleEntitiesSearch = (value: string) => {
  const result = value.match(/[^, | \s]+/g);
  if (isNullish(result) || result?.length === 1) {
    return value;
  }

  return result;
};
