import {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core';

/** file text icon */
export const faSettings: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'settings' as IconName,
  icon: [
    16,
    16,
    [],
    '',
    'M9.20832 7.79167C7.8881 7.79167 6.77878 6.8887 6.46425 5.66667H1.41666V4.25H6.46425C6.77878 3.02796 7.8881 2.125 9.20832 2.125C10.7731 2.125 12.0417 3.39353 12.0417 4.95833C12.0417 6.52314 10.7731 7.79167 9.20832 7.79167ZM13.4583 4.25H15.5833V5.66667H13.4583V4.25ZM5.66666 14.875C4.34644 14.875 3.23712 13.972 2.92259 12.75H1.41666V11.3333H2.92259C3.23712 10.1113 4.34644 9.20833 5.66666 9.20833C7.23146 9.20833 8.49999 10.4769 8.49999 12.0417C8.49999 13.6065 7.23146 14.875 5.66666 14.875ZM9.91666 12.75H15.5833V11.3333H9.91666V12.75ZM7.08332 12.0417C7.08332 12.8241 6.44906 13.4583 5.66666 13.4583C4.88425 13.4583 4.24999 12.8241 4.24999 12.0417C4.24999 11.2593 4.88425 10.625 5.66666 10.625C6.44906 10.625 7.08332 11.2593 7.08332 12.0417ZM10.625 4.95833C10.625 5.74074 9.99073 6.375 9.20832 6.375C8.42592 6.375 7.79166 5.74074 7.79166 4.95833C7.79166 4.17593 8.42592 3.54167 9.20832 3.54167C9.99073 3.54167 10.625 4.17593 10.625 4.95833Z',
  ],
};
