import { createAction, props } from '@ngrx/store';

import { Configurations, ConfigurationValue } from '../models/config.model';

/**
 * Configuration Module init action
 */
export const initConfig = createAction(
  '[Configuration] Init configuration',
  props<{ featureName: string }>(),
);

export const loadConfig = createAction(
  '[Configuration] Load configuration',
  props<{ featureName: string }>(),
);

/**
 * Configuration Module init success action
 */
export const initConfigSuccess = createAction(
  '[Configuration] Init Configurations Success',
  props<{ configurations: Configurations; namespaces: string[] }>(),
);

/**
 * Configuration Module init failure action
 */
export const initConfigFailure = createAction(
  '[Configuration] Init Configurations Failure',
  props<{ error: string }>(),
);

export const upsertToConfig = createAction(
  '[Configuration] Upsert to Configurations',
  props<{
    namespace: string;
    featureName: string;
    value?: ConfigurationValue;
  }>(),
);

export const updateRules = createAction(
  '[Configuration] Update Rules',
  props<{
    allRulesFetchAtOnce: boolean;
  }>(),
);
