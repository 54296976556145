// tslint:disable:no-any
export function applyMixins(derivedConstructor: any, baseConstructors: any[]) {
  baseConstructors.forEach((baseConstructor) => {
    Object.getOwnPropertyNames(baseConstructor.prototype).forEach((name) => {
      Object.defineProperty(
        derivedConstructor.prototype,
        name,
        Object.getOwnPropertyDescriptor(baseConstructor.prototype, name) as any,
      );
    });
  });
}

// tslint:disable:jsdoc-format
/**
 *
 * @param ctors classes with which you want to extend your base class
 * as a result you would have single class that you can extend with your
 * component.
 * For example:
 *
  class DeliveryPeriodsWrapperComponent
    extends (
      multipleInheritance<WrapperMixin>([DeliveryPeriodsCopy, DeliveryPeriodsDelete])
    ) {}
 */
// TODO: FIXME: as soon as TS 4.2 rolled out use
// type AbstractConstructor<T> = abstract new (...args: any[]) => T
// to type the constructor
// reference https://devblogs.microsoft.com/typescript/announcing-typescript-4-2-rc/#abstract-construct-signatures
// !Note Beaware, it inherits only prototypes, properties of base classes are omitted
export function multipleInheritance<T>(ctors: any[]): T {
  // tslint:disable:prefer-const
  let resultClass: new (...args: any[]) => any = class ResultClass {};

  applyMixins(resultClass, ctors);

  return (resultClass as unknown) as T;
}
