import * as Sentry from '@sentry/angular';

interface ISentryUserExtras {
  accountId?: string;
  basketId?: string;
  siteId?: string;
}
let user: Partial<Sentry.User> & ISentryUserExtras = {};

export function registerUserDefinition(email: string, username: string) {
  if (user.email === email) return;

  // re-initiate
  user = { email, username };
  Sentry.setUser(user);
}

export function registerSentryUserAccount(accountId: string) {
  Object.assign(user, { accountId });
  Sentry.setUser(user);
}

export function registerSentryUserBasket(basketId: string) {
  Object.assign(user, { basketId });
  Sentry.setUser(user);
}

export function registerSentryUserSiteId(siteId: string) {
  Object.assign(user, { siteId });
  Sentry.setUser(user);
}
