import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
  environmentSettings,
  EnvironmentSettings,
} from '../environment-settings';
import { ENVIRONMENT_API } from '../tokens';

/**
 * Service for loading the environment settings.
 */
@Injectable()
export class EnvironmentService {
  /**
   * Subject which provides the latest environment settings.
   */
  private readonly environmentSettingsSubject = new BehaviorSubject(
    environmentSettings,
  );

  get environmentSettingsValue() {
    return this.environmentSettingsSubject.value;
  }

  /**
   * Observable exposing the BehaviorSubject holding the current environment settings.
   */
  get environmentSettings() {
    return this.environmentSettingsSubject.asObservable();
  }

  /**
   * Constructor
   * @param httpHandler Angular httpBackend handler
   */
  constructor(
    @Inject(ENVIRONMENT_API) private environmentApiUrl: string,
    private httpHandler: HttpBackend,
  ) {}

  /**
   * Requests the environment settings
   */
  public fetchEnvironment() {
    return new HttpClient(this.httpHandler)
      .get<EnvironmentSettings>(this.environmentApiUrl)
      .pipe(
        map((settings) => ({ ...environmentSettings, ...settings })),
        tap((settings) => this.environmentSettingsSubject.next(settings)),
      );
  }
}
