import { NgModule } from '@angular/core';

import { environmentSettings } from './environment-settings';
import { EnvironmentService } from './services/environment.service';
import { ENVIRONMENT_API, ENVIRONMENT_SETTINGS } from './tokens';

/**
 * Module which single responsibility is to fetch the environment settings.
 */
@NgModule({
  providers: [
    EnvironmentService,
    {
      provide: ENVIRONMENT_SETTINGS,
      useValue: environmentSettings,
    },
    {
      provide: ENVIRONMENT_API,
      useValue: '/api/v2/configuration/environment',
    },
  ],
})
export class EnvironmentModule {}
