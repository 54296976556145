import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  IImageBreakpoint,
  MediaObjectFit,
  MediaObjectPosition,
} from '@epicuro-next/ataraxia/models';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

import { ImageService } from '../../image.service';

import { defaultImageBreakpoints } from './default-image-breakpoints';

// TODO handle retina displays by multiplying maxWidth

/**
 * Component to show an image and a loader while it still loads
 */
@Component({
  selector: 'atx-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent implements OnChanges, OnDestroy {
  public static readonly defaultFailedLoadIconSize = '2x';

  @ViewChild('imgRef')
  public imgRef: ElementRef<HTMLImageElement>;

  @HostBinding('class.image-load-error')
  public loadFailed = false;

  public imageVisible = false;

  /** The url of the image to be loaded */
  @Input()
  public imageUrl: string;

  /**
   * Since all this progressive load thing is kinda experimental, I've added a flag for to enable it manually.
   * Should be removed in future or set to true by default
   */
  @Input()
  public enableProgressiveLoad = false;

  /** Provide an Url for a fallback image if the main one fails to load */
  @Input()
  public fallbackImageUrl: string;

  /** The icon to be used as a loading indicator */
  @Input()
  public title = '';

  /** The icon to be used as a loading indicator */
  @Input()
  public loaderIcon = faSpinner;

  /** The max width to use, when it doesn't depend on the breakpoints */
  @Input()
  public maxWidth: number;

  /** The max height to use, when it doesn't depend on the breakpoints */
  @Input()
  public maxHeight: number;

  /** Configuration values for picture source */
  @Input()
  public imageBreakpoints?: IImageBreakpoint[];

  /** Size of failed icon */
  @Input()
  public failedLoadIconSize = ImageComponent.defaultFailedLoadIconSize;

  /** Object fit strategy */
  @Input()
  public objectFit: MediaObjectFit = MediaObjectFit.CONTAIN;

  @Input()
  public objectPosition: MediaObjectPosition = MediaObjectPosition.CenterTop;

  @HostBinding('class')
  public get imgClass() {
    return [this.objectFit, this.objectPosition];
  }

  @Output()
  public imageLoaded = new EventEmitter<void>();

  @Output()
  public imageError = new EventEmitter();

  /** Loading state of the image */
  public loading = true;

  public previewLoading = true;

  /**
   * Component constructor
   * @param imageService - image service
   */
  constructor(
    private imageService: ImageService,
    private renderer: Renderer2,
  ) {}

  public get processedImageUrl() {
    if (!this.imageUrl) {
      return '';
    }

    const paramChar = this.imageUrl.includes('?') ? '&' : '?';
    let imageUrl = this.imageUrl.endsWith(paramChar)
      ? this.imageUrl
      : `${this.imageUrl}${paramChar}`;

    imageUrl += 'mode=max&scale=both';

    if (this.maxWidth) {
      imageUrl += `&maxWidth=${this.maxWidth}`;
    }

    if (this.maxHeight) {
      imageUrl += `&maxHeight=${this.maxHeight}`;
    }

    return imageUrl;
  }

  public get sortedImageBreakPoints() {
    let breakpoints = defaultImageBreakpoints;
    if (this.imageBreakpoints && this.imageBreakpoints.length > 0) {
      breakpoints = this.imageBreakpoints;
    }

    if (this.maxWidth) {
      breakpoints = breakpoints.filter(
        (breakpoint) =>
          (breakpoint.maxWidth ?? breakpoint.width ?? 0) <= this.maxWidth,
      );
    }

    if (this.maxHeight) {
      breakpoints = breakpoints.filter(
        (breakpoint) =>
          (breakpoint.maxHeight ?? breakpoint.height ?? 0) <= this.maxHeight,
      );
    }

    return breakpoints.sort((a, b) => (b.width ?? 0) - (a.width ?? 0));
  }

  public ngOnChanges() {
    this.loading = true;
    this.loadFailed = false;
  }

  public ngOnDestroy() {
    if (this.imgRef) {
      this.renderer.setAttribute(this.imgRef.nativeElement, 'src', '');
    }
  }

  /** The loading complete handler for the image */
  public loadComplete(): void {
    this.loading = false;
    this.imageLoaded.emit();
  }

  public onPreviewLoaded() {
    this.previewLoading = false;
  }

  /** The load failed handler for the image */
  public loadFail(): void {
    this.loading = false;
    this.previewLoading = false;
    this.loadFailed = true;
    this.imageError.emit();
  }

  /** Media string generator */
  public getMedia(width: string): string {
    return `(min-width:${width})`;
  }

  /** Srcset for picture source generator */
  public getSrcSet(item: IImageBreakpoint): string {
    return this.imageService.getSrcSet(this.imageUrl, item);
  }

  public imageIntersected(value: boolean) {
    if (!this.imageVisible) {
      this.imageVisible = value;
    }
  }
}
