import { createAction, props } from '@ngrx/store';

import { SessionState } from './model';

/**
 * Action dispatched upon module initialization
 */
export const initializeSession = createAction('[Session] Initialize Session');

/**
 * Patch session variables in the state
 */
export const patchSession = createAction(
  '[Session] Patch Session',
  props<{ session: Partial<SessionState> }>(),
);
