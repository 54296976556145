export const DEFAULT_EVENT_HUB_NAME = 'reportingeventhub';
export const EVENT_HUB_DOMAIN = 'servicebus.windows.net';
export const AZURE_EVENT_HUB_NAMESPACE = 'azure-event-hub';

export enum AzureEventHubConfigurationKeys {
  DisabledEventNames = 'disabled-event-names',
}

export interface AzureEventHubConfiguration {
  [AzureEventHubConfigurationKeys.DisabledEventNames]: string[];
}
