import { Inject, Injectable, Optional } from '@angular/core';
import {
  AbstractLoggerService,
  IErrorLog,
  IMessageLog,
} from '@epicuro-next/models/platform/monitoring';

import { LOGGER_TRANSPORT_TOKEN } from '../tokens';

/**
 * Facade service for logging. Uses Angular dependency injection to get registered logger services.
 * Services should extend AbstractLoggerService and be provided for `LOGGER_TRANSPORT_TOKEN` with `multi: true` option
 */
@Injectable()
export class LoggerService extends AbstractLoggerService {
  /**
   * Constructor
   * @param transports Implementations of the AbstractLoggerService
   */
  constructor(
    @Optional()
    @Inject(LOGGER_TRANSPORT_TOKEN)
    private transports: AbstractLoggerService[],
  ) {
    super();

    if (!transports) {
      this.transports = [];
    }
  }

  /**
   * Logns an exception
   * @param errorLog exception to log
   */
  public exception(errorLog: IErrorLog): void {
    this.transports.forEach((transport) => {
      try {
        transport.exception(errorLog);
      } catch (e) {}
    });
  }

  /**
   * Logs a debug message
   * @param messageLog
   */
  public message(messageLog: IMessageLog): void {
    this.transports.forEach((transport) => {
      try {
        transport.message(messageLog);
      } catch (e) {}
    });
  }
}
