import {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core';

/** megaphone shout icon */
export const faMegaphoneShout: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'megaphone-shout' as IconName,
  icon: [
    16,
    8,
    [],
    '',
    'M0 3.14286C0 2.83929 0.25 2.57143 0.571429 2.57143C0.767857 2.57143 0.928572 2.69643 1.03571 2.85714L8.57143 0.571429V7.42857L6.21429 6.73211C6.01783 7.46429 5.35714 8 4.57143 8C3.625 8 2.85714 7.23211 2.85714 6.28571C2.85714 6.10714 2.89286 5.91074 2.94643 5.75L1.03571 5.16071C0.928572 5.32143 0.767857 5.42857 0.571429 5.42857C0.25 5.42857 0 5.17857 0 4.85714V3.14286ZM5.39286 6.48217L3.76786 5.98217C3.73214 6.08926 3.71429 6.17857 3.71429 6.28571C3.71429 6.76789 4.08929 7.14286 4.57143 7.14286C4.96429 7.14286 5.30357 6.85714 5.39286 6.48217ZM9.42857 0H10C10.1429 0 10.2857 0.142857 10.2857 0.285714V7.71429C10.2857 7.87497 10.1429 8 10 8H9.42857C9.26789 8 9.14286 7.87497 9.14286 7.71429V0.285714C9.14286 0.142857 9.26789 0 9.42857 0ZM13.1769 0.384748C13.3894 0.172259 13.7339 0.172259 13.9464 0.384748C14.1589 0.597257 14.1589 0.941771 13.9464 1.15426L13.1769 1.92378C12.9644 2.13626 12.6199 2.13626 12.4074 1.92378C12.1949 1.71129 12.1949 1.36677 12.4074 1.15426L13.1769 0.384748ZM13.9465 7.31C14.159 7.09754 14.159 6.75297 13.9465 6.54051L13.177 5.77097C12.9645 5.55849 12.6199 5.55849 12.4075 5.77097C12.195 5.98349 12.195 6.328 12.4075 6.54051L13.177 7.31C13.3895 7.52251 13.734 7.52251 13.9465 7.31ZM15.1007 3.30309C15.4013 3.30309 15.6449 3.54672 15.6449 3.84718C15.6449 4.14772 15.4013 4.3913 15.1007 4.3913H13.5617C13.2612 4.39132 13.0176 4.14767 13.0176 3.84718C13.0176 3.5467 13.2613 3.30309 13.5617 3.30309H15.1007Z',
  ],
};
