import { isNullish } from './nullish-check';

export function ensureArray<T>(value: T[] | T | undefined): T[] {
  if (isNullish(value)) {
    return [];
  }

  if (Array.isArray(value)) {
    return value;
  }

  return Array.isArray(value) ? value : [value];
}
