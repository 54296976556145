import { Injectable } from '@angular/core';
import {
  IKeyValue,
  IUserContext,
} from '@epicuro-next/models/platform/monitoring';
import { ITelemetryItem } from '@microsoft/applicationinsights-web';

import { AbstractContextService } from '../../abstract/abstract-context.service';

import { AppInsightsService } from './app-insights.service';

/**
 * Context service for App Insights logger
 */
@Injectable()
export class AppInsightsContextService extends AbstractContextService {
  /** Telemetry data to pass to the AppInsights Service */
  private telemetryData: { [key: string]: string | number } = {};

  /**
   * Constructor. Adds telemetry to App Insights service
   * @param appInsightsService
   */
  constructor(private appInsightsService: AppInsightsService) {
    super();

    appInsightsService.instance.addTelemetryInitializer(
      this.telemetryInitializer.bind(this),
    );
  }

  /**
   * Sets the user information
   * @param user
   */
  public setUser(data: IUserContext) {
    if (data) {
      this.appInsightsService.instance.setAuthenticatedUserContext(
        data.id.replace(/[,;=| ]+/g, '_'),
      );
    } else {
      this.appInsightsService.instance.clearAuthenticatedUserContext();
    }
  }

  /**
   * Sets the tags for the app
   * @param tags
   */
  public setTags(data: IKeyValue) {
    Object.keys(data).forEach((key: string) => {
      const value = data[key];
      if (value === null || value === undefined) {
        delete this.telemetryData[key];
      } else {
        this.telemetryData[key] = value;
      }
    });
  }
  /**
   * Sets the release version to log
   * @param data
   */
  public setRelease(data: string) {
    this.appInsightsService.instance.context.application.ver = data;
  }

  /**
   * Initializes the telemetry and assigns item.data
   * @param item
   */
  private telemetryInitializer(item: ITelemetryItem): boolean | void {
    const telemetryData: IKeyValue = {};
    Object.keys(this.telemetryData).forEach((key: string) => {
      telemetryData[key] = this.telemetryData[key];
    });

    if (!item.data) {
      item.data = {};
    }
    item.data = { ...item.data, ...telemetryData };
  }
}
