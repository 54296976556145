import { GCRPayLoad } from './gcr-payload';

export interface TrackingConfig {
  trackType?: GATrackType;
  eventName?: string;
  sendToGA?: boolean;
  gaPayload?: {
    [key: string]: unknown;
    send_to?: string;
  };
  sendToGCR?: boolean;
  gcrPayload?: GCRPayLoad | GCRPayLoad[];
}

export enum GATrackType {
  Config = 'config',
  Set = 'set',
  Event = 'event',
}
