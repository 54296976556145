import { distinctUntilKeyChanged, map, Observable } from 'rxjs';

export function distinctUntilChangedBy<T>(
  serializationMethod: (value: T) => object,
) {
  return (source$: Observable<T>) => {
    return source$.pipe(
      map((value) => {
        return {
          json: JSON.stringify(serializationMethod(value)),
          value,
        };
      }),
      distinctUntilKeyChanged('json'),
      map(({ value }) => value),
    );
  };
}
