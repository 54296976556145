export function getDiscountPercentageInCorrectRange(
  discountPercentage: number,
): {
  discountPercentage: number;
  hasChanged: boolean;
} {
  let hasChanged = false;
  if (discountPercentage < 0 && discountPercentage >= -100) {
    hasChanged = true;
    discountPercentage = discountPercentage * -1;
  }

  if (discountPercentage > 1 && discountPercentage <= 100) {
    hasChanged = true;
    discountPercentage = discountPercentage / 100;
  }

  if (Math.abs(discountPercentage) > 100) {
    hasChanged = true;
    // What can we do .... ?
    discountPercentage = 0;
  }

  return { discountPercentage, hasChanged };
}
