import { Inject, ModuleWithProviders, NgModule, Optional } from '@angular/core';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { ImageModule } from '@epicuro-next/ataraxia/image';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { PULSE_FEATURE_NAME } from '@epicuro-next/constants/rule-engine/pulse-rules';
import { RuleEngineModule } from '@epicuro-next/platform/rule-engine';
import {
  BROWSER_STORAGE_SERVICE,
  LocalStorageService,
} from '@epicuro-next/platform/storage';
import { storageMetaReducer } from '@epicuro-next/state/meta-reducers';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';

import { FeatureFlagDirective } from './directives/feature-flag.directive';
import { ConfigurationsService } from './services/configurations/configurations.service';
import { initConfig } from './state/configuration.actions';
import { ConfigurationEffects } from './state/configuration.effects';
import {
  configurationFeatureKey,
  reducer,
} from './state/configuration.reducer';
import {
  APP_NAME_TOKEN,
  CONFIGURATIONS_CONFIG_TOKEN,
  CONFIGURATIONS_STORAGE_KEY,
  FEATURES_NAME_TOKEN,
  FEATURE_NAME_TOKEN,
} from './tokens';

export function getConfigFactoryConfig(
  browserStorageKey: string,
  storageService: BROWSER_STORAGE_SERVICE,
) {
  return {
    metaReducers: [storageMetaReducer([], browserStorageKey, storageService)],
  };
}

@NgModule({
  imports: [
    StoreModule.forFeature(
      configurationFeatureKey,
      reducer,
      CONFIGURATIONS_CONFIG_TOKEN,
    ),
    EffectsModule.forFeature([ConfigurationEffects]),
    RuleEngineModule,
    ImageModule,
  ],
  providers: [
    ConfigurationsService,
    {
      provide: CONFIGURATIONS_STORAGE_KEY,
      useValue: 'configurations_storage',
    },
    {
      provide: CONFIGURATIONS_CONFIG_TOKEN,
      deps: [CONFIGURATIONS_STORAGE_KEY, LocalStorageService],
      useFactory: getConfigFactoryConfig,
    },
    LocalStorageService,
  ],
  declarations: [FeatureFlagDirective],
  exports: [FeatureFlagDirective],
})
export class ConfigurationModule {
  constructor(
    @Optional() @Inject(FEATURE_NAME_TOKEN) featureName: string[],
    @Optional() @Inject(FEATURES_NAME_TOKEN) featuresName: string[],
    @Optional() @Inject(APP_NAME_TOKEN) appName: string,
    store: Store,
  ) {
    // because different feature modules may inject configuration module with the same
    // feature name we have to ensure that we fetch unique fields
    const features = new Set([
      appName,
      PULSE_FEATURE_NAME,
      ...featureName,
      ...featuresName,
    ]);

    if (features && features.size) {
      features.forEach((feature) =>
        store.dispatch(initConfig({ featureName: feature })),
      );
    }
  }

  public static forFeature(
    value: string | string[],
  ): ModuleWithProviders<ConfigurationModule> {
    let providers;
    if (Array.isArray(value)) {
      providers = value.map((feature) => ({
        provide: FEATURE_NAME_TOKEN,
        useValue: feature,
        multi: true,
      }));
    } else {
      providers = [
        {
          provide: FEATURE_NAME_TOKEN,
          useValue: value,
          multi: true,
        },
      ];
    }
    return {
      ngModule: ConfigurationModule,
      providers,
    };
  }

  public static forRoot(
    features: string[],
  ): ModuleWithProviders<ConfigurationModule> {
    return {
      ngModule: ConfigurationModule,
      providers: [
        {
          provide: FEATURES_NAME_TOKEN,
          useValue: features,
        },
      ],
    };
  }
}
