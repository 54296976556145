export enum LayerType {
  /**
   * For Esprit this is the UA Layer. For the other tenants this is the only layer (so can be UA/GA4)
   */
  Layer1 = 'layer1',

  /**
   * For Esprit this is the GA4 Layer. The other tenants don't have a second layer
   */
  Layer2 = 'layer2',
}
