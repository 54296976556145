import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ILanguage, ILanguageEntry } from './i18n.interface';
import { LANGUAGES_API_URL_TOKEN } from './tokens';

/**
 * I18n service
 */
@Injectable()
export class I18nService {
  /**
   * language code regex
   */
  private languageCodeRegex = /^(.*?)(?:\s?\((.*?)\))?$/;

  /**
   * Dependencies
   */
  constructor(
    @Inject(LANGUAGES_API_URL_TOKEN) private languagesApiUrl: string,
    private http: HttpClient,
  ) {}

  /**
   * Get available languages from remote
   */
  public getAvailableLanguages(): Observable<ILanguageEntry[]> {
    return this.http
      .get<ILanguage[]>(this.languagesApiUrl)
      .pipe(map((data: ILanguage[]) => this.adaptLanguagesInfo(data)));
  }
  /**
   * Adapt languages
   */
  private adaptLanguagesInfo(languages: ILanguage[]): ILanguageEntry[] {
    return languages.map((entry) => this.adaptLanguageInfo(entry));
  }

  /**
   * Adapt single language
   */
  private adaptLanguageInfo(languageCode: ILanguage): ILanguageEntry {
    const parts = languageCode.name.match(this.languageCodeRegex);

    return {
      id: languageCode.locale,
      name: parts !== null ? parts[1] : '',
      ...(parts !== null && parts[2] ? { addition: parts[2] } : {}),
    };
  }
}
