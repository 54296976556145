import { createAction, props } from '@ngrx/store';

import { ILanguageEntry } from '../i18n.interface';

/**
 * Action to set the language
 */
const setLanguage = createAction(
  '[I18n] Set Current Language',
  props<{ selected: string }>(),
);

const setLoginLanguage = createAction(
  '[I18n] Set Language On Login',
  props<{ selected: string }>(),
);

const languageReady = createAction('[I18n] Language is ready');

/**
 * Action to load languages
 */
const loadLanguages = createAction('[I18n] Load Languages');

/**
 * Action to set available languages
 */
const setAvailableLanguages = createAction(
  '[I18n] Set Available Languages',
  props<{ availables: ILanguageEntry[] }>(),
);

/**
 * Action to signal the failure of loadLanguages effect
 */
const loadLanguagesFail = createAction('[I18n] Load Languages Fail');

/**
 * List of available actions
 */
export const languageActions = {
  loadLanguagesFail,
  loadLanguages,
  setLanguage,
  setLoginLanguage,
  languageReady,
  setAvailableLanguages,
};
