import { fromEvent } from 'rxjs';
import { debounceTime, map, shareReplay, startWith } from 'rxjs/operators';

import {
  ATX_BREAKPOINT_MEDIA,
  ATX_BREAKPOINT_WIDTH,
  ATX_DEVICE_SIZE,
} from './breakpoints';

const SAFE_CONTINIOUS_EVENT_DEBOUNCE = 200;

export const atxWidth$ = fromEvent(window, 'resize').pipe(
  debounceTime(SAFE_CONTINIOUS_EVENT_DEBOUNCE),
  map(() => window.innerWidth),
  shareReplay({ bufferSize: 1, refCount: true }),
  startWith(window.innerWidth),
);

export const atxIsDesktop$ = atxWidth$.pipe(
  map((width) => width > ATX_BREAKPOINT_WIDTH.XL),
  shareReplay({ bufferSize: 1, refCount: true }),
);

export const atxIsTablet$ = atxWidth$.pipe(
  map(
    (width) =>
      width > ATX_BREAKPOINT_WIDTH.MD && width <= ATX_BREAKPOINT_WIDTH.XL,
  ),
  shareReplay({ bufferSize: 1, refCount: true }),
);

export const atxIsMobile$ = atxWidth$.pipe(
  map((width) => width <= ATX_BREAKPOINT_WIDTH.MD),
  shareReplay({ bufferSize: 1, refCount: true }),
);

export const atxIsScreenXl$ = atxWidth$.pipe(
  map((width) => width <= ATX_BREAKPOINT_WIDTH.XL),
  shareReplay({ bufferSize: 1, refCount: true }),
);

export const atxIsScreenXxl$ = atxWidth$.pipe(
  map((width) => width <= ATX_BREAKPOINT_WIDTH.XXL),
  shareReplay({ bufferSize: 1, refCount: true }),
);

export const atxIsScreenLg$ = atxWidth$.pipe(
  map((width) => width <= ATX_BREAKPOINT_WIDTH.LG),
  shareReplay({ bufferSize: 1, refCount: true }),
);

export const atxIsScreenSm$ = atxWidth$.pipe(
  map((width) => width <= ATX_BREAKPOINT_WIDTH.SM),
  shareReplay({ bufferSize: 1, refCount: true }),
);

export const atxNotMobile$ = atxIsMobile$.pipe(
  map((isMobile) => !isMobile),
  shareReplay({ bufferSize: 1, refCount: true }),
);

export const atxIsScreenNotSm$ = atxIsScreenSm$.pipe(
  map((isSm) => !isSm),
  shareReplay({ bufferSize: 1, refCount: true }),
);

export const atxIsScreenNotLg$ = atxIsScreenLg$.pipe(
  map((isLg) => !isLg),
  shareReplay({ bufferSize: 1, refCount: true }),
);

export const atxIsScreenNotXl$ = atxIsScreenXl$.pipe(
  map((isXl) => !isXl),
  shareReplay({ bufferSize: 1, refCount: true }),
);

export const atxIsScreenNotXxl$ = atxIsScreenXxl$.pipe(
  map((isXl) => !isXl),
  shareReplay({ bufferSize: 1, refCount: true }),
);

export const atxMatchesDevice$ = (device: ATX_DEVICE_SIZE) =>
  atxWidth$.pipe(
    map(() => window.matchMedia(ATX_BREAKPOINT_MEDIA[device]).matches),
  );

export const atxMatchingDeviceSize$ = atxWidth$.pipe(
  map((width) => {
    const minToMaxSortedDeviceSize = Object.entries(ATX_BREAKPOINT_WIDTH).sort(
      (a, b) => b[1] - a[1],
    );
    const matchingPair = minToMaxSortedDeviceSize.find(
      ([, value]) => width >= value,
    );
    return (matchingPair ? matchingPair[0] : 'XS') as ATX_DEVICE_SIZE;
  }),
  shareReplay({ bufferSize: 1, refCount: true }),
);

export const atxIsTouchScreen = (() => {
  let hasTouchScreen = false;
  if ('maxTouchPoints' in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ('msMaxTouchPoints' in navigator) {
    // tslint:disable-next-line: no-any
    hasTouchScreen = (navigator as any).msMaxTouchPoints > 0;
  } else {
    const mQ = matchMedia?.('(pointer:coarse)');
    if (mQ?.media === '(pointer:coarse)') {
      hasTouchScreen = !!mQ.matches;
    } else if ('orientation' in window) {
      hasTouchScreen = true;
    } else {
      // tslint:disable-next-line: no-any
      const UA = (navigator as any).userAgent;
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }

  return hasTouchScreen;
})();
