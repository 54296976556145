import { EnvironmentSettings } from '@epicuro-next/platform/environment';

import { GTAGConfig } from '../interfaces/gtag-config';

export function GTagConfigFactory(
  environmentSettings: EnvironmentSettings,
): GTAGConfig[] | null {
  if (!environmentSettings.gtag) {
    return null;
  }

  try {
    return JSON.parse(environmentSettings.gtag) as GTAGConfig[];
  } catch (error) {
    console.error(error);
    return null;
  }
}
