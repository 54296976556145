import { IconPrefix } from '@fortawesome/fontawesome-svg-core';

/** Colors icon parts */
export const faColors = [
  'M8.23296,0.13696 C8.23296,0.05376 8.24128,0.02048 8.34112,0.02432 C10.26112,0.09728 11.92768,0.784 13.34464,2.08 C13.46048,2.1856 13.45984,2.18624 13.34656,2.30016 C12.46848,3.17824 11.58976,4.05568 10.71424,4.93568 C10.6496,5.00096 10.6144,5.00288 10.54208,4.94336 C9.91692207,4.42086899 9.14544927,4.10454502 8.33344,4.03776 C8.24832,4.03008 8.23168,4.00256 8.23228599,3.92256 C8.23616,3.2896 8.23424,2.65664 8.23424,2.02368 C8.23424,1.3952 8.23552,0.76608 8.23296,0.13696 Z',
  'M7.66208,3.91424 C7.66208,3.99616 7.64928,4.02944 7.5552,4.0384 C6.73828851,4.10678316 5.9628593,4.42751159 5.33632,4.95616 C5.2768,5.00608 5.248,4.99328 5.19936,4.94464 C4.3027886,4.04494609 3.40465391,3.1468114 2.50496,2.25024 C2.44928,2.19584 2.45632,2.16896 2.50944,2.11968 C3.51424,1.18976 4.67008,0.5408 6.00832,0.22976 C6.50432,0.11456 7.00672,0.04928 7.51552,0.02624 C7.62816,0.02112 7.66464,0.0416 7.66339236,0.16384 C7.65632,0.78912 7.66016,1.41376 7.66016,2.03904 C7.66016,2.66368 7.65888,3.28896 7.66208,3.91424 Z',
  'M11.98592,7.62368 C11.9116215,6.88790652 11.6329594,6.18759325 11.18144,5.60192 L11.14496,5.55392 C10.99712,5.34976 10.99712,5.34976 11.18144,5.16544 C12.0224,4.32448 12.86528,3.4848 13.7024,2.64 C13.79392,2.5472 13.84,2.56 13.9168,2.6464 C14.31424,3.09632 14.67776,3.57056 14.976,4.09216 C15.3293429,4.70648798 15.5912942,5.36901995 15.7536,6.05888 C15.8464,6.45312 15.91296,6.8512 15.94176,7.25568 C15.95072,7.38048 15.95456,7.50464 15.96352,7.62816 C15.9712,7.728 15.9392,7.75936 15.8368,7.75873885 C15.216,7.75424 14.5952,7.75616 13.9744,7.75616 C13.36128,7.75616 12.74816,7.75424 12.13568,7.75808 C12.03328,7.75873885 11.99872,7.74016 11.98592,7.62368 Z',
  'M0.13632,7.75810804 C0.03968,7.75872 0.00192,7.73824 0.00576,7.63264 C0.04032,6.64064 0.24832,5.6896 0.64832,4.78016 C1.00096,3.98016 1.48992,3.2704 2.07616,2.62528 C2.14656,2.54848 2.18496,2.55616 2.25536,2.62592 C3.13408,3.50976 4.01536,4.39104 4.89856,5.26912 C4.97088,5.3408 4.96704,5.38752 4.90816,5.456 C4.42944,6.01344 4.14016,6.6592 4.0128,7.38048 C3.99808,7.46752 3.9808,7.55584 3.98208,7.64288 C3.98336,7.73504 3.95136,7.75872 3.86304,7.75810804 C3.3184,7.75424 2.77376,7.75616 2.22912,7.75616 L1.9872,7.75616 C1.37024,7.7568 0.75328,7.75424 0.13632,7.75810804 Z',
  'M13.98144,8.26304 C14.60224,8.26304 15.22304,8.2656 15.84384,8.26048 C15.94496,8.26048 15.97184,8.28608 15.96864,8.38784 C15.90016,10.13376 15.32096,11.68512 14.20608,13.0336 C14.10368,13.15776 13.99104,13.2736 13.88992,13.39904 C13.82848,13.47584 13.78944,13.46816 13.72288,13.40096 C12.9696,12.6432 12.21312,11.88864 11.45728,11.1328 C11.32928,11.0048 11.20256,10.87488 11.072,10.7488 C11.02848,10.7072 11.02336,10.67968 11.06496,10.63104 C11.5341104,10.0778136 11.8422815,9.40634587 11.95584,8.68992 C11.97312,8.58752 11.9904,8.48256 11.98976,8.37824 C11.98976,8.27904 12.02816,8.26048 12.1184,8.26112 C12.7392,8.26496 13.36,8.26304 13.9808,8.26304',
  'M3.84896,8.256 C3.94432,8.256 3.97056,8.2848 3.97696,8.37568 C4.03650673,9.19736655 4.35438245,9.97917504 4.88512,10.60928 C4.94272,10.67904 4.93504,10.7104 4.87488,10.76992 C3.99424,11.64672 3.11552,12.5248 2.24,13.40608 C2.17408,13.472 2.14528,13.4688 2.08384,13.40224 C0.94144,12.16128 0.2464,10.71424 0.05376,9.03168 C0.02816,8.80768 0.0128,8.5824 0.00064,8.35648 C-0.00384,8.2752 0.01984,8.25664 0.09792,8.25728 C0.72704,8.25984 1.3568,8.25856 1.98592,8.25856 C2.60672,8.25856 3.22752,8.26112 3.84896,8.256 Z',
  'M8.23168,12.19136 C8.2304,12.08576 8.26112,12.05888 8.36096,12.04928 C9.19001386,11.9772394 9.97490198,11.6442837 10.60288,11.09824 C10.65408,11.05344 10.68224,11.05664 10.72832,11.10336 C11.61664,11.99488 12.50624,12.88448 13.39712,13.77344 C13.4464,13.82272 13.44576,13.84832 13.3952,13.89504 C12.32768,14.88448 11.0944,15.55264 9.66208,15.83936 C9.23456,15.92512 8.80256,15.97312 8.36672,15.99488 C8.24768,16.00128 8.23168,15.9616 8.23232,15.85728 C8.2368,15.24416 8.23424,14.63168 8.23424,14.01856 C8.23424,13.40928 8.23744,12.8 8.23168,12.19136 Z',
  'M7.7344,15.86688 C7.73568,15.96928 7.7056,15.99488 7.60512,15.99104 C5.91744,15.92704 4.40704,15.3824 3.08352,14.32832 C2.92352,14.20032 2.77248,14.05952 2.61312,13.93024 C2.5312,13.86304 2.5216,13.81888 2.60544,13.73632 C3.47834242,12.8699214 4.34789557,12.0001549 5.21408,11.12704 C5.28768,11.0528 5.328,11.06432 5.39648,11.12384 C5.80148689,11.4714558 6.27383121,11.7318116 6.784,11.88864 C7.0336,11.96736 7.28832,12.02368 7.55072,12.04416 C7.73248,12.05824 7.73248,12.0608 7.73248,12.23808 L7.73248,14.02816 C7.73248,14.64128 7.72992,15.25376 7.7344,15.86688 Z',
].map((part) => ({
  prefix: 'fac' as IconPrefix,
  iconName: 'colors',
  icon: [16, 16, [], '', part],
}));

/** Colors icon fills */
export const faColorsFills = [
  '#40BB64',
  '#FF6600',
  '#FFDE00',
  '#E41616',
  '#0099CC',
  '#333333',
  '#A542D1',
  '#A0A0A0',
];
