import { StringOrStringArray } from './string-or-string-array';

/**
 * The first level in the array is an OR operation: at least one of the regexes must match.
 * The second level is an AND operation: all regexes in the array must match.
 * ['a', 'b'] equals 'a' || 'b'
 * [['a', 'b'], 'c'] equals ('a' && 'b') || 'c'
 * ['a', ['b', 'c']] equals 'a' || ('b' && 'c')
 * [['a', 'b'], ['c', 'd']] equals ('a' && 'b') || ('c' && 'd')
 * [['a', 'b', 'c'], ['d', 'e', 'f']] equals ('a' && 'b' && 'C') || ('d' && 'e' && 'f')
 */
export function orAndRegexMatcher(
  input: string,
  regexes: StringOrStringArray,
): boolean {
  if (typeof regexes === 'string') {
    if (regexes === '') {
      // Empty string is a special case: it matches everything.
      return true;
    }
    // Base case: just a single regex.
    const regex = new RegExp(regexes);
    return regex.test(input);
  } else {
    // Recursive case: an array of regexes.
    return matchForArrays(input, regexes);
  }
  return false; // No match found.
}

function matchForArrays(input: string, regexes: StringOrStringArray): boolean {
  for (const regex of regexes) {
    if (Array.isArray(regex)) {
      // AND operation: all regexes in the array must match.
      if (regex.every((p) => orAndRegexMatcher(input, p))) {
        return true;
      }
    } else {
      // OR operation: at least one regex must match.
      if (orAndRegexMatcher(input, regex)) {
        return true;
      }
    }
  }

  return false;
}
