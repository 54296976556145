import { Site } from 'graphql-api/gql-api-interfaces';

export const sortSites = (sitesOrder: string[], sites: Site[]) => {
  return [...sites].sort((a, b) => {
    const idxA = sitesOrder.indexOf(a.siteId);
    const idxB = sitesOrder.indexOf(b.siteId);
    const valueOfA =
      idxA < 0
        ? sites.findIndex((site) => site.siteId === a.siteId) + sites.length
        : idxA;
    const valueOfB =
      idxB < 0
        ? sites.findIndex((site) => site.siteId === b.siteId) + sites.length
        : idxB;
    return valueOfA - valueOfB;
  });
};
