import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, RendererFactory2 } from '@angular/core';
import { EventTrack } from 'angulartics2';

import { GTMConfig } from '../interfaces/gtm-config';
import { IGtmProperties } from '../interfaces/properties';
import { LayerType } from '../models/layertype';
import { GTM_GONFIG_TOKEN } from '../tokens';

import { AbstractTrackClient } from './abstract-track-client';
import { Angulartics } from './angulartics.service';

// @ts-ignore
@Injectable()
export class GTMService extends AbstractTrackClient {
  private initialized = false;

  private userProperties: IGtmProperties = {};

  constructor(
    protected renderFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private angulartics: Angulartics,
    @Inject(GTM_GONFIG_TOKEN) private settings: GTMConfig,
  ) {
    super(renderFactory);

    this.initialize();
  }

  public startTracking() {
    if (this.trackingStarted) {
      return;
    }

    this.angulartics.setUsername.subscribe((value: string) =>
      this.setUsername(value),
    );

    this.angulartics.eventTrack.subscribe((value: EventTrack) =>
      this.eventTrack(value.action, value.properties),
    );
    this.angulartics.setUserProperties.subscribe((value: IGtmProperties) =>
      this.setUserProperties(value),
    );
    this.angulartics.setUserPropertiesOnce.subscribe((value: IGtmProperties) =>
      this.setUserProperties(value),
    );
    this.angulartics.ecommerceEventTrack.subscribe((value: IGtmProperties) =>
      this.ecommerceEventTrack(value),
    );

    this.angulartics.sendTrackEvent.subscribe((value) =>
      this.trackEvent(value.payload, value.layerType),
    );

    this.trackingStarted = true;
  }

  public sendTrackEvent(payload: IGtmProperties, layerType?: LayerType) {
    this.angulartics.sendTrackEvent.next({
      payload,
      layerType: layerType as LayerType,
    });
  }

  private pushLayer(payload: IGtmProperties, layerType?: LayerType) {
    if (!this.settings) return;

    switch (layerType) {
      case LayerType.Layer1:
        this.pushLayer1(payload);
        break;
      case LayerType.Layer2:
        this.pushLayer2(payload);
        break;
      default:
        this.pushLayer1(payload);
        this.pushLayer2(payload);
    }
  }

  private pushLayer1(payload: IGtmProperties) {
    const gtmDataLayer = this.settings.dataLayerName
      ? // @ts-ignore
        (window[this.settings.dataLayerName] as unknown[])
      : undefined;

    if (typeof gtmDataLayer !== 'undefined' && gtmDataLayer) {
      gtmDataLayer.push(payload);
    }
  }

  private pushLayer2(payload: IGtmProperties) {
    const gtmDataLayerV4 = this.settings.dataLayerNameV4
      ? // @ts-ignore
        (window[this.settings.dataLayerNameV4] as unknown[])
      : undefined;

    if (
      this.settings.v4Enabled &&
      typeof gtmDataLayerV4 !== 'undefined' &&
      gtmDataLayerV4
    ) {
      gtmDataLayerV4.push(payload);
    }
  }

  private eventTrack(action: string, properties: IGtmProperties) {
    properties = properties || {};

    this.pushLayer({
      event: properties.event || 'pulse.Track',
      target: properties.category || 'Event',
      category: properties.category,
      action,
      label: properties.label,
      value: properties.value,
      interactionType: properties.noninteraction,
      userId: this.angulartics.settings.gtm.userId,
      ...this.userProperties,
    });
  }

  private ecommerceEventTrack(value: IGtmProperties) {
    const { clearPrevious, ...payload } = value;

    if (clearPrevious) {
      this.pushLayer({ ecommerce: null });
    }

    this.pushLayer(payload);
  }

  private trackEvent(payload: IGtmProperties, layerType?: LayerType) {
    if (payload?.hasOwnProperty('clearPrevious')) {
      this.pushLayer({ ecommerce: null }, layerType);
      delete payload.clearPrevious;
    }

    this.pushLayer(payload, layerType);
  }

  private setUsername(userId: string) {
    this.userProperties.dimension1 = userId;
    this.angulartics.settings.gtm.userId = userId;
  }

  private setUserProperties(value: IGtmProperties) {
    if (value.hasOwnProperty('userId')) {
      this.setUsername(value.userId as string);

      return;
    }

    if (value.hasOwnProperty('accountId')) {
      this.userProperties.dimension2 = value.accountId;

      return;
    }

    this.userProperties = {
      ...this.userProperties,
      ...value,
    };
  }

  private initialize() {
    if (this.initialized || !this.settings) {
      return;
    }

    this.appendToHeadScript(this.settings.key, this.settings.dataLayerName);
    this.initializeNS(this.settings.key);

    if (this.settings.v4Enabled) {
      this.appendToHeadScript(
        this.settings.keyV4 as string,
        this.settings.dataLayerNameV4 as string,
      );
      this.initializeNS(this.settings.keyV4 as string);
    }

    this.initialized = true;
  }

  private appendToHeadScript(key: string, dataLayerName: string) {
    const headScript = this.renderer.createElement('script');
    const gtmJsParams = [
      this.settings.auth && `gtm_auth=${this.settings.auth}`,
      this.settings.previewEnv && `gtm_preview=${this.settings.previewEnv}`,
      this.settings.cookiesWin && `gtm_cookies_win=${this.settings.cookiesWin}`,
    ]
      .filter(Boolean)
      .join('&');

    headScript.text = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '${
        gtmJsParams ? '&' + gtmJsParams : ''
      }';f.parentNode.insertBefore(j,f);
      })(window,document,'script','${dataLayerName}','${key}');
    `;

    // add logic for registering routes on the page
    headScript.text += `window['${dataLayerName}'].push({
      originalLocation:
        document.location.protocol +
        '//' +
        document.location.hostname +
        document.location.pathname +
        document.location.search,
    });`;

    this.renderer.appendChild(this.document.head, headScript);
  }
  private initializeNS(key: string) {
    const noScript = this.renderer.createElement('noscript');

    const iframe: HTMLIFrameElement = this.renderer.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${key}`;
    iframe.width = '0';
    iframe.height = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';

    noScript.appendChild(iframe);

    this.renderer.appendChild(this.document.body, noScript);
  }
}
