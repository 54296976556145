import { utcDateTime } from './utc-date-time';

export function firstDayOfWeekUTC(date: string) {
  return utcDateTime(date).startOf('week').set({ hour: 12 }).toISO({
    suppressMilliseconds: true,
  });
}

export function lastDayOfWeekUTC(date: string) {
  return utcDateTime(date)
    .endOf('week')
    .startOf('day')
    .set({ hour: 12 })
    .toISO({
      suppressMilliseconds: true,
    });
}
