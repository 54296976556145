/**
 * Utility method to quickly find the largest number for
 * which `predicate(number)` still returns true.
 * @param  max      Highest possible number
 * @param  predicate Should return true as long as the passed number is valid
 * @return          Largest possible number
 */
export function numericBinarySearch(
  max: number,
  predicate: (n: number) => boolean,
): number {
  let low = 0;
  let high = max;
  let best = -1;
  let mid: number;

  while (low <= high) {
    // tslint:disable-next-line:no-bitwise
    mid = ~~((low + high) / 2);
    const result = predicate(mid);
    if (!result) {
      high = mid - 1;
    } else {
      best = mid;
      low = mid + 1;
    }
  }

  return best;
}
