import { Injectable } from '@angular/core';
import { LayerType } from '@epicuro-next/platform/tracking';
import { Angulartics2 } from 'angulartics2';
import { ReplaySubject } from 'rxjs';

import { IGtmProperties } from '../interfaces/properties';

@Injectable()
export class Angulartics extends Angulartics2 {
  public ecommerceEventTrack: ReplaySubject<IGtmProperties> = new ReplaySubject(
    10,
  );

  // tslint:disable-next-line:no-any
  public setUserProperties: ReplaySubject<any> = new ReplaySubject(20);

  public sendTrackEvent = new ReplaySubject<{
    payload: IGtmProperties;
    layerType: LayerType;
  }>(10);
}
