/**
 * Interface for media: 360 images, images, videos.
 */
export interface IMedia {
  /** url to use for the media */
  url: string;
  /** urls used for 360 images */
  urls?: string[];
  /** Type of media */
  type: MediaType;
}

export enum MediaType {
  IMAGE = 'IMAGE',
  IMAGE360 = 'IMAGE360',
  VIDEO = 'VIDEO',
}
