import { Address } from 'graphql-api/gql-api-interfaces';

// used in CS
export function getCountryFallBack(
  country: string,
  countryCode?: string | null,
) {
  return countryCode && country.toLowerCase() === 'unknown'
    ? countryCode
    : country;
}

// Used in account selector
export function getCountryCodeFallBack(
  country: string,
  countryCode?: string | null,
) {
  return !!countryCode ? countryCode.toUpperCase() : country;
}

export function getFullShipToAddress(
  {
    street,
    city,
    country,
    countryCode,
  }: // tslint:disable-next-line:max-union-size
  Pick<Address, 'street' | 'city' | 'country' | 'countryCode'>,
  options: {
    revertAddress?: boolean;
    countryCodePreferred?: boolean;
  },
): string {
  const { revertAddress = true, countryCodePreferred = true } = options;
  const streetDisplayValue = revertAddress
    ? street?.split(' ').reverse().join(' ')
    : street;
  const countryCodeDisplayValue = countryCodePreferred
    ? getCountryCodeFallBack(country, countryCode)
    : getCountryFallBack(country, countryCode);
  return [streetDisplayValue, city, countryCodeDisplayValue]
    .filter(Boolean)
    .join(', ');
}
