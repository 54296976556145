import { Injectable } from '@angular/core';
import { IImageBreakpoint } from '@epicuro-next/ataraxia/models';

/**
 * Service with utils methods, which is useful outside image library
 */
@Injectable()
export class ImageService {
  /** Srcset for picture source generator */
  public getSrcSet(imageUrl: string, item: IImageBreakpoint): string {
    if (!imageUrl) {
      return '';
    }

    const params: string[] = [];

    if (item.maxHeight) {
      params.push(`maxHeight=${item.maxHeight}`);
    }

    if (item.height) {
      params.push(`height=${item.height}`);
    }

    if (item.maxWidth) {
      params.push(`maxWidth=${item.maxWidth}`);
    }

    if (item.width) {
      params.push(`width=${item.width}`);
    }

    if (item.mode) {
      params.push(`mode=${item.mode}`);
    }

    if (item.scale) {
      params.push(`scale=${item.scale}`);
    }

    if (params.length === 0) {
      return imageUrl;
    }

    return imageUrl.includes('?')
      ? `${imageUrl}&${params.join('&')}`
      : `${imageUrl}?${params.join('&')}`;
  }
}
