// duplicate from ataraxia models, makes circular dependency
type UTCDeliveryPeriod = {
  from: string;
  to?: string;
};

export const ISO_INTERVAL_DELIMITER = '/';

export const isIntervalISODate = (date: string) =>
  date.includes(ISO_INTERVAL_DELIMITER);

export const makeIntervalISODate = (from: string, to: string) =>
  `${from}${ISO_INTERVAL_DELIMITER}${to}`;

export const intervalISODateToDateRange = (date: string): UTCDeliveryPeriod => {
  const [from, to] = date.split(ISO_INTERVAL_DELIMITER);
  return { from, to };
};
