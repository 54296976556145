import { DateTime } from 'luxon';

export const getUTCNow = (): string => {
  return DateTime.now().toUTC().toISO({
    suppressMilliseconds: true,
  });
};

export const getUTCNowDate = (): string => {
  return DateTime.now().toUTC().toISODate();
};
