// tslint:disable:no-commented-code
// tslint:disable:no-console
// tslint:disable:no-any
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { environmentSettings } from '@epicuro-next/platform/environment';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { lazyLoadTranslations } from '@epicuro-next/platform/i18n';
import { initSentry } from '@epicuro-next/platform/sentry';
import 'hammerjs';

import { UNPROTECTED_RESOURCES } from './app/auth/unprotected-resources';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';

const FAILED_TO_LOAD_ENV_ERROR = 'FAILED_TO_LOAD_ENV_ERROR';
const FAILED_TO_LOCALE_ERROR = 'FAILED_TO_LOCALE_ERROR';

if (environment.production) {
  enableProdMode();
}

/**
 * Need to know environment setting even before bootstrap so Pulse behave correctly
 * Known dependencies:
 *  1. AuthenticationService needs to know where to store sessionId. It cannot re-set
 * storage place after the creation.
 */
export const loadEnvironmentSettings = async (retryOnFailCount = 5) => {
  let envSettings;

  do {
    try {
      const envSettingsResponse = await fetch(
        UNPROTECTED_RESOURCES.ENVIRONMENT_API,
      );
      envSettings = await envSettingsResponse.json();
      retryOnFailCount = -1;
    } catch {
      if (retryOnFailCount > 0) {
        retryOnFailCount = retryOnFailCount - 1;
      } else {
        throw Error(FAILED_TO_LOAD_ENV_ERROR);
      }
    }
  } while (retryOnFailCount >= 0);

  // side effect, so we update setting object that is used in application
  Object.assign(environmentSettings, envSettings);
};

async function loadEnvironmentAndStartSentry() {
  await loadEnvironmentSettings(5);

  // According to sentry documentations, it's best to initialize sentry before angular
  const { sentryDsn } = environmentSettings;
  if (sentryDsn) initSentry(sentryDsn, environmentSettings); // don't await!
}

// hmr https://github.com/angular/angular-cli/issues/17324#issuecomment-686472836
declare var module: any;

/**
 * loading app module function
 */
async function loadAppModule() {
  const appModule = await import('./app/app.module');

  try {
    const bootstrap = async () => {
      return platformBrowserDynamic()
        .bootstrapModule(appModule.AppModule)
        .then((mod) => {
          return mod;
        })
        .catch((err) => console.error(err));
    };

    if (environment.hmr) {
      // tslint:disable-next-line:no-string-literal
      if (module['hot']) {
        hmrBootstrap(module, bootstrap);
      } else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
      }
    } else {
      bootstrap().catch((err) => console.log(err));
    }
  } catch (err) {
    console.error(err);
  }
}

async function init() {
  try {
    await Promise.all([
      lazyLoadTranslations().catch(() => {
        return Promise.reject(new Error(FAILED_TO_LOCALE_ERROR));
      }),
      loadEnvironmentAndStartSentry(),
    ]);

    loadAppModule();
  } catch (err) {
    if (
      err.message === FAILED_TO_LOAD_ENV_ERROR ||
      err.message === FAILED_TO_LOCALE_ERROR
    ) {
      alert(
        'Something went wrong. Please try to reload your page or contact your customer service',
      );
    }
  }
}

init();
