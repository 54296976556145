/**
 * Resources that can be consumed without an authorization header
 */
export const UNPROTECTED_RESOURCES = {
  THEME_API: `/api/v2/configuration/theme`,
  // Endpoint from which the environment configuration is fecthed:
  LANGUAGE_API: `/api/translations/languages`,
  ENVIRONMENT_API: `/api/v2/configuration/environment`,
  ORDERS_CONFIGURATION_API: `/api/v2/configuration/orders`,
  LOGIN_MERCHANDISE_API: `/api/merchandising/login-page`,
  SUPPORT_PAGE_MERCHANDISE_API: `/api/merchandising/support-page`,
  MESSAGES_MERCHANDISE_API: `/api/merchandising/messages`,
  FORGOT_PASSWORD_API: `/api/users/password/reset`,
};
