import { BasketItemOrigin } from '@epicuro-next/data/baskets-dto';
import {
  BasketItemSourceInput,
  BasketUpdateItem,
} from 'graphql-api/baskets-api-interfaces';

type BasketItemSource = Pick<BasketUpdateItem, 'source' | 'originInformation'>;

export function getMergedBasketItemSource(
  sourceInput: BasketItemSourceInput = { type: '' },
  originInformation: Record<string, unknown> = {},
  existingBasketItemOrigin?: BasketItemOrigin,
): BasketItemSource {
  let source: BasketItemSourceInput;
  let mergedOriginInformation: Record<string, unknown>;

  try {
    source = existingBasketItemOrigin?.source
      ? JSON.parse(existingBasketItemOrigin.source)
      : sourceInput;
  } catch {
    source = sourceInput;
  }

  if (existingBasketItemOrigin?.originInformation) {
    try {
      const prevOriginInformation = JSON.parse(
        existingBasketItemOrigin.originInformation,
      );
      mergedOriginInformation = {
        ...prevOriginInformation,
        operationsHistory: (
          prevOriginInformation?.operationsHistory ?? []
        ).concat({
          source: sourceInput,
          originInformation,
        }),
      };
    } catch {
      mergedOriginInformation = originInformation;
    }
  } else {
    mergedOriginInformation = originInformation;
  }

  return {
    source,
    originInformation: mergedOriginInformation,
  };
}
