import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, RendererFactory2 } from '@angular/core';

import { GCRConfig } from '../interfaces/gcr-config';
import { TrackingConfig } from '../models/tracking-config';
import { GCR_CONFIG_TOKEN } from '../tokens';

import { AbstractTrackClient } from './abstract-track-client';

const DEFAULT_LOCATION_ID = 'global';
const DEFAULT_CATALOG_ID = 'default_catalog';

@Injectable()
export class GCRService extends AbstractTrackClient {
  private initialized = false;
  private cloudRetail: { logEvent: (arg0: [string, unknown][]) => void };

  constructor(
    protected renderFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(GCR_CONFIG_TOKEN) private settings: GCRConfig | null,
  ) {
    super(renderFactory);

    this.initialize();
  }

  private initialize(): void {
    if (this.initialized || !this.settings) {
      return;
    }

    this.appendToHeadScript();
    this.initialized = true;
  }

  public sendTrackEvent(payload: TrackingConfig): void {
    if (!payload.sendToGCR || !payload.gcrPayload || !this.trackingStarted) {
      return;
    }

    if (Array.isArray(payload.gcrPayload)) {
      payload.gcrPayload.forEach((singlePayLoad) =>
        this.sendSingleTrackEvent(singlePayLoad),
      );
    } else {
      this.sendSingleTrackEvent(payload.gcrPayload);
    }
  }

  private sendSingleTrackEvent(event: {
    [key: string]: unknown;
    eventType: string;
    visitorId: string;
  }) {
    const eventSettings: [string, unknown][] = [];
    eventSettings.push(['apiKey', this.settings?.apiKey]);
    eventSettings.push(['projectId', this.settings?.projectId]);
    eventSettings.push([
      'locationId',
      this.settings?.locationId ?? DEFAULT_LOCATION_ID,
    ]);
    eventSettings.push([
      'catalogId',
      this.settings?.catalogId ?? DEFAULT_CATALOG_ID,
    ]);
    eventSettings.push(['logEvent', event]);

    this.cloudRetail.logEvent(eventSettings);
  }

  public startTracking(): void {
    this.cloudRetail =
      // tslint:disable-next-line:no-any
      (window as any).cloud_retail;

    if (this.cloudRetail && this.settings) {
      this.trackingStarted = true;
    }
  }

  private appendToHeadScript() {
    const gcrScript = this.renderer.createElement('script');
    gcrScript.type = 'text/javascript';
    gcrScript.async = true;
    gcrScript.src = 'https://www.gstatic.com/retail/v2_event.js';

    this.renderer.appendChild(this.document.head, gcrScript);
  }
}
