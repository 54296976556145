import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';

import { LocalStorageService } from './services/local-storage.service';
import { SessionStorageService } from './services/session-storage.service';

export type BROWSER_STORAGE_SERVICE =
  | LocalStorageService
  | SessionStorageService;

export const BROWSER_STORAGE_TOKEN = new InjectionToken(
  'BROWSER_STORAGE_TOKEN',
);

@NgModule({
  imports: [CommonModule],
  providers: [
    LocalStorageService,
    SessionStorageService,
    {
      provide: BROWSER_STORAGE_TOKEN,
      useClass: LocalStorageService,
    },
  ],
})
export class StorageModule {}
