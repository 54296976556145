export function decodeUriComponentRecursively(
  value?: string | null,
): string | null | undefined {
  if (!value) {
    return value;
  }

  let previous = value;
  let current = value;
  do {
    previous = current;
    current = decodeURIComponent(current);
  } while (current !== previous);

  return current;
}
