import {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core';

/** pin icon */
export const faThinPin: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'thin-pin' as IconName,
  icon: [
    14,
    14,
    [],
    '',
    'M13.8932 4.00951L9.95433 0.105798C9.81214 -0.035266 9.58125 -0.035266 9.43906 0.105798L9.42237 0.122339C9.18084 0.361635 9.04776 0.67995 9.04776 1.01842C9.04776 1.23648 9.10366 1.44573 9.20752 1.63128L5.08611 5.1368C4.77068 4.85741 4.36808 4.7045 3.94166 4.7045C3.48214 4.7045 3.05018 4.88183 2.72535 5.20382L2.70064 5.22831C2.55831 5.3693 2.55831 5.59798 2.70064 5.73897L5.06038 8.07762L2.76215 10.3552C2.71617 10.4021 1.62835 11.5131 0.913166 12.3972C0.232094 13.2388 0.0974106 13.3931 0.090414 13.401C-0.0360343 13.5437 -0.0290377 13.759 0.106302 13.8938C0.177215 13.9643 0.270721 14 0.364519 14C0.450008 14 0.535716 13.9704 0.604661 13.9107C0.610711 13.9055 0.76274 13.7745 1.61756 13.0953C2.50948 12.3865 3.63046 11.3083 3.6814 11.2591L5.97591 8.98511L8.20927 11.1985C8.2804 11.2691 8.37369 11.3043 8.4669 11.3043C8.56012 11.3043 8.65348 11.2691 8.72454 11.1985L8.74924 11.174C9.07415 10.8521 9.25307 10.4239 9.25307 9.96859C9.25307 9.54597 9.09871 9.14697 8.81688 8.83437L12.354 4.74979C12.5412 4.85272 12.7524 4.90812 12.9724 4.90812C13.314 4.90812 13.6351 4.7763 13.8766 4.53686L13.8932 4.52032C14.0356 4.37918 14.0356 4.1505 13.8932 4.00951Z',
  ],
};
