import { SortDirection, SortParamsOfType } from '@epicuro-next/models/sorting';

export function toggleSortDirection<T extends string | number>(
  newType: T,
  current?: SortParamsOfType<T> | null,
) {
  if (!current || !isTypesEqual(current.type, newType)) {
    return SortDirection.Asc;
  }

  return current.direction.toLowerCase() === SortDirection.Asc.toLowerCase()
    ? SortDirection.Desc
    : SortDirection.Asc;
}

function isTypesEqual<T extends string | number>(oldType: T, newType: T) {
  if (typeof oldType === 'string' && typeof newType === 'string') {
    return oldType.toLowerCase() === newType.toLowerCase();
  }

  return oldType === newType;
}
