import {
  intervalISODateToDateRange,
  isIntervalISODate,
  makeIntervalISODate,
} from '../iso-interval/iso-interval';

import { getUTCAfternoon } from './get-utc-afternoon';

// Date can be a single or an interval. Each part of interval should be mapped as well
export const mapIsoDateToUTCAfternoon = (date: string): string => {
  if (isIntervalISODate(date)) {
    const { from, to } = intervalISODateToDateRange(date);
    if (!to) {
      return mapIsoDateToUTCAfternoon(from);
    }
    return makeIntervalISODate(
      mapIsoDateToUTCAfternoon(from),
      mapIsoDateToUTCAfternoon(to),
    );
  }
  return getUTCAfternoon(date);
};
