export function toBoolean(value: string | number): boolean {
  if (!value) {
    return false;
  }

  if (typeof value === 'boolean') {
    return value;
  }

  if (typeof value === 'number' && isFinite(value)) {
    return Boolean(value);
  }

  if ((value as string).toLowerCase() === 'true') {
    return true;
  }

  if (value === '1') {
    return true;
  }

  if ((value as string).toLowerCase() === 'yes') {
    return true;
  }

  return false;
}

export function canBeBoolean(value: string | number): boolean {
  if (!value) {
    return false;
  }

  if (typeof value === 'number' && isFinite(value)) {
    return true;
  }

  if (value === 'true' || value === 'false') {
    return true;
  }

  return false;
}
