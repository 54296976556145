import { inject, Injectable } from '@angular/core';

import { IGtmProperties } from '../interfaces/properties';
import { LayerType } from '../models';
import { TrackingConfig } from '../models/tracking-config';

import { AbstractTrackClient } from './abstract-track-client';
import { Angulartics } from './angulartics.service';
import { GCRService } from './gcr.service';

@Injectable()
export class TrackingService {
  private readonly trackingClient = inject(AbstractTrackClient);

  private readonly gcrClient = inject(GCRService);

  constructor(private angulartics: Angulartics) {}

  public eventTrack(action: string, properties: IGtmProperties) {
    this.angulartics.eventTrack.next({
      action,
      properties: {
        event: properties.event || 'pulse.Track',
        ...properties,
      },
    });
  }

  public ecommerceEventTrack(payload: IGtmProperties) {
    this.angulartics.ecommerceEventTrack.next(payload);
  }

  public sendEventTrack(
    payload: IGtmProperties | TrackingConfig,
    layerType: LayerType,
  ) {
    this.trackingClient.sendTrackEvent(payload, layerType);
    this.gcrClient.sendTrackEvent(payload);
  }

  public setUserId(userId: string) {
    this.angulartics.setUsername.next(userId);
  }

  public setAccountId(value: string | null) {
    this.angulartics.setUserProperties.next({
      accountId: value,
    });
  }

  public setUserProperties(value: IGtmProperties) {
    this.angulartics.setUserProperties.next(value);
  }
}
