import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ProgressiveImageDirective } from './progressive-image.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ProgressiveImageDirective],
  exports: [ProgressiveImageDirective],
})
export class ProgressiveImageModule {}
