import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  selectAccountId,
  selectSiteId,
} from '@epicuro-next/state/session-state';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

import { IRuleRequest } from '../rule-engine.interface';
import { RULE_ENGINE_API } from '../tokens';

/**
 * Intercept API requests and append the session headers to it
 */
@Injectable()
export class RuleInterceptor implements HttpInterceptor {
  private readonly url = inject(RULE_ENGINE_API);
  /**
   * Dependencies
   */
  constructor(private store: Store) {}

  /**
   * The intercept method to be called by angular
   */
  public intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    if (!request.url.startsWith(`${this.url}/process`)) {
      return next.handle(request);
    }

    return combineLatest([
      this.store.select(selectAccountId),
      this.store.select(selectSiteId),
    ]).pipe(
      take(1),
      mergeMap(([accountId, siteId]) => {
        if (!accountId || !siteId) {
          return next.handle(request);
        }
        const newRequest = request.clone();
        const body = newRequest.body as IRuleRequest;
        body.ruleContextParams = {
          ...body.ruleContextParams,
          siteId,
          accountId,
        };
        return next.handle(newRequest);
      }),
    );
  }
}
