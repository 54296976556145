import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OrderType } from 'graphql-api/gql-api-interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IRuleContext,
  IRuleParameters,
  IRuleValue,
} from './rule-engine.interface';
import { RULE_ENGINE_API } from './tokens';

type OrderTypesSupported = OrderType.Now | OrderType.Future;

/**
 * Service to run single/multiple rules by name or namespace. This temporarily rely on the old apis (v1)
 * TODO: implement changes based on api v2.
 */
@Injectable()
export class RuleEngine {
  private readonly orderTypeMapping: {
    [OrderType.Now]: 1;
    [OrderType.Future]: 2;
  } = {
    NOW: 1,
    FUTURE: 2,
  };

  /**
   * Dependencies
   */
  constructor(
    private http: HttpClient,
    @Inject(RULE_ENGINE_API) private ruleEngineApiUrl: string,
  ) {}

  public getAllRules(featureNames: string[], context?: IRuleContext) {
    return this.http.post<IRuleValue[]>(`${this.ruleEngineApiUrl}/process`, {
      namespaces: featureNames,
      rules: [],
      ruleContextParams: context
        ? this.processContext({
            ...context,
          })
        : {},
    });
  }

  /**
   * Evaluate rules.
   */
  public getValues(
    namespace: string = '',
    rules: string[] = [],
    context?: IRuleContext,
  ): Observable<IRuleValue[]> {
    return this.http.post<IRuleValue[]>(`${this.ruleEngineApiUrl}/process`, {
      namespace,
      rules,
      ruleContextParams: context
        ? this.processContext({
            ...context,
          })
        : {},
    });
  }

  // TODO: we should implement the rule engine similar to the configuration types and sort these generics out once and for all
  /**
   * Evaluate single rule.
   */
  public getFirstValue<T = string>(
    namespace: string = '',
    rule: string,
    context?: IRuleContext,
  ): Observable<T> {
    return this.getValues(namespace, [rule], context).pipe(
      map((rules) => rules[0].value),
    ) as unknown as Observable<T>;
  }

  /**
   * Enrich provided context with additional input.
   */
  private processContext(context: IRuleContext) {
    const data: Partial<IRuleParameters> = {};

    if (context.product) {
      data.brandId = context.product.brandId;
      if (
        context.processVariations &&
        context.product?.productId !== undefined
      ) {
        data.productIds = [
          context.product.productId,
          ...(context.product.children || []).map(
            (product) => product.productId,
          ),
        ];
      } else {
        data.productId = context.product.productId;
      }
    }

    if (context.productIds) {
      data.productIds = new Array(...new Set(context.productIds));
    }

    if (context.orderType && this.isSupportedOrderType(context.orderType)) {
      data.orderType = this.orderTypeMapping[context.orderType];
    }

    if (context.brandOrganisationId) {
      data.brandOrganisationId = context.brandOrganisationId;
    }

    data.accountId = context.accountId;
    data.siteId = context.siteId;

    return data;
  }

  private isSupportedOrderType(
    orderType: OrderType,
  ): orderType is OrderTypesSupported {
    return orderType in this.orderTypeMapping;
  }
}
