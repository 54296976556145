/**
 * Returns the value of the property specified by the path.
 * @param obj The object to get the property value from.
 * @param path The path to the property, expressed as [address][street] e.g.
 * @returns The value or undefined.
 */
export function getPropertyValueByPath<T extends object>(
  obj: T,
  path: string,
): unknown | undefined {
  if (obj === null || obj === undefined) {
    return undefined;
  }

  const propertyPath = path
    .replace(/\[|\]\s*/g, '.')
    .split('.')
    .filter((key) => key.trim() !== '');

  // tslint:disable-next-line:no-any
  let value: any = obj;

  for (const key of propertyPath) {
    if (key in value) {
      value = value[key];
    } else {
      return undefined;
    }
  }

  return value;
}
