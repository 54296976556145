export const getDeliveryShopId = (item?: {
  accountId: string;
  addressId?: string | null;
}): string => `${item?.accountId ?? ''}-${item?.addressId ?? ''}`;

export const getDeliveryShopKeys = (
  deliveryShopId: string,
): { accountId: string; addressId?: string } => {
  const [accountId, addressId] = deliveryShopId.split('-');

  if (getDeliveryShopId({ accountId, addressId }) !== deliveryShopId) {
    console.error(
      '[Error][getDeliveryShopKeys] should not happen. Incorrect shop unique key',
      deliveryShopId,
    );
  }

  return {
    accountId,
    addressId,
  };
};
