/**
 * Defines a period for a delivery. It is defined by the 'from' date and the 'to' date.
 */
export class DeliveryPeriod {
  /**
   *
   * @param from Date from which the delivery period is valid
   * @param to Date until which the delivery period is valid
   */
  constructor(public from: Date, public to?: Date) {}
}

export type UTCDeliveryPeriod = {
  from: string;
  to?: string;
};
