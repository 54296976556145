import { Inject, Injectable } from '@angular/core';
import { IRuleContext, RuleEngine } from '@epicuro-next/platform/rule-engine';
import { isNonNullish } from '@epicuro-next/utilities';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { Configurations } from '../../models/config.model';
import { ConfigurationState } from '../../state/configuration.reducer';
import { selectConfigurationValue } from '../../state/configuration.selectors';
import { APP_NAME_TOKEN } from '../../tokens';

export interface ConfigRequest<T, K extends keyof T> {
  featureName: K;
  defaultValue: T[K];
  namespace?: string;
}

/**
 * Service to provide access to the current configs in the store
 */
@Injectable()
export class ConfigurationsService {
  /**
   * Dependencies
   */
  constructor(
    private store: Store<ConfigurationState>,
    @Inject(APP_NAME_TOKEN) private appName: string,
    private ruleEngineService: RuleEngine,
  ) {}

  /**
   * Only subscribe to one value, don't care about changes.
   */
  // TODO: FIXME: Add generic return value and remove typecasting (`as Obser..`)
  // from consumers
  public getConfigValue<
    T extends Configurations<K> = Configurations,
    K extends string = string,
  >({ featureName, defaultValue, namespace }: ConfigRequest<T, K>) {
    return this.store
      .select(selectConfigurationValue, {
        namespace: namespace ?? this.appName,
        featureName,
      })
      .pipe(
        map((value) => (value ?? defaultValue) as T[K]),
        filter(isNonNullish),
        distinctUntilChanged(),
      );
  }

  public fetchConfigValue<
    T extends Configurations<K> = Configurations,
    K extends string = string,
  >({
    namespace,
    featureName,
    defaultValue,
    context,
  }: {
    namespace: string;
    featureName: string;
    defaultValue?: unknown;
    context?: IRuleContext;
  }) {
    return this.ruleEngineService
      .getValues(namespace, [featureName], context)
      .pipe(
        map((rules) => {
          const rule = rules.find(
            (returnedRule) => returnedRule.name === featureName,
          );

          return rule?.value ?? rule?.results;
        }),
        map((value) => {
          const v = (value ?? defaultValue) as T[K];
          return v;
        }),
        catchError(() => {
          return of(defaultValue);
        }),
      );
  }
}
