import { NgModule } from '@angular/core';
import { ConfigurationModule } from '@epicuro-next/platform/configuration';

import { AnalyticsService } from './services/analytics.service';
import { AzureEventhubService } from './services/azure-eventhub.service';
import { AZURE_EVENT_HUB_NAMESPACE } from './services/configurations';
import { LoggerService } from './services/logger.service';
import { MonitoringContextService } from './services/monitoring-context.service';
import { ANALYTICS_TRANSPORT_TOKEN } from './tokens';

@NgModule({
  imports: [ConfigurationModule.forRoot([AZURE_EVENT_HUB_NAMESPACE])],
  providers: [
    MonitoringContextService,
    LoggerService,
    AnalyticsService,
    {
      provide: ANALYTICS_TRANSPORT_TOKEN,
      useClass: AzureEventhubService,
      multi: true,
    },
  ],
})
export class MonitoringModule {}
