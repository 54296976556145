export interface EnvironmentSettings {
  /** DSN sentry integration. If not defined sentry won't be initialised */
  sentryDsn?: string;
  /** Sentry trace sample rate for performance monitoring. Double [0 - 1] */
  sentryTraceSampleRate?: number;
  /** API Key for App Insights */
  appInsightsApiKey?: string;
  /** CDN url */
  cdnUrl: string;
  /** Google Tag Manager key */
  gtmKey?: string;
  /** Google Tag Manager data layer name */
  gtmDataLayerName?: string;
  /** Google Tag Manager auth token */
  gtmAuth?: string;
  /** Google Tag Manager preview env */
  gtmPreview?: string;
  gtmCookiesWin?: string;
  /** Google Tag Manager key V4 */
  gtmKeyV4?: string;
  /** Google Tag Manager data layer name V4 */
  gtmDataLayerNameV4?: string;
  /** Google Tag */
  gtag?: string;
  /** Google Cloud Retail api */
  gcr?: string;
  /** Browser title of the application */
  applicationTitle?: string;
  /** Max upload in Mb */
  maxUploadSize: string;
  /** Multi site supports */
  multiSiteSupport: string;
  /** Builder.io API key */
  builderIOApiKey?: string;
  /** export request config */
  exportSettings?: string;
  /** Enable v2 rule engine url */
  enableV2RuleEngine: boolean;
  /** Ionic URL */
  ionicCdnUrl: string;
  /** bestseller, puma etc.. */
  tenant?: string;
  /** test, acc, staging, prod */
  env?: string;
  /** App Domain */
  appDomain?: string;
  intercomAppId?: string;
  cookieConsent?: string;
  basketItemCategoryAttributeKey?: string;
  ordersSettings?: string;
  servicenowVirtualAgent?: string;
  basketItemDivisionAttributeKey?: string;
  thirdPartyScripts?: string;
  auth: string;
  /** use identity endpoint instead of connect */
  useIdentityNotConnect?: string;
}

/**
 * Settings for the environment: in general vendor configurations.
 * API keys, client id, etc.
 * Will be set before bootstraping in main.ts file
 */
export const environmentSettings: EnvironmentSettings = {
  cdnUrl: 'static',
  multiSiteSupport: 'false',
  maxUploadSize: '0', // 0 means no limit check
  enableV2RuleEngine: false,
  ionicCdnUrl:
    'https://cdn.jsdelivr.net/npm/@ionic/core@5.6.13/dist/ionic/ionic.esm.js',
  basketItemCategoryAttributeKey: 'category',
  ordersSettings:
    '{"cdnUrl":"https://epicurobestsellertest.azureedge.net","defaultImageType":"", "enableOrderMaterials": "false"}',
  auth: '{needToAgreeToTerms: false}',
};
