import {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core';

/** copy to multiple icon */
export const faCopyToMultiple: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'copy-to-multiple' as IconName,
  icon: [
    16,
    16,
    [],
    '',
    'M14.5 3C15.8785 3 17 4.12151 17 5.5V12H18.5C19.3284 12 20 11.3284 20 10.5V1.5C20 0.67157 19.3284 0 18.5 0H9.5C8.67157 0 8 0.67157 8 1.5V3H14.5ZM10.5 7C11.8785 7 13 8.12151 13 9.5V16H14.5C15.3284 16 16 15.3284 16 14.5V5.5C16 4.67157 15.3284 4 14.5 4H5.5C4.67157 4 4 4.67157 4 5.5V7H10.5ZM0 18.5C0 19.3284 0.67157 20 1.5 20H10.5C11.3284 20 12 19.3284 12 18.5V9.5C12 8.67155 11.3284 8 10.5 8H1.5C0.67157 8 0 8.67157 0 9.5V18.5Z',
  ],
};
