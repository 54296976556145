const regExp = /(\d+).(\d+)-(\d+)/;

export type DigitsTransformationInfo = {
  minIntegerDigitsToAdd?: number;
  minFractionDigitsToAdd?: number;
  maxFractionDigitsToAdd?: number;
};
export class DigitsInfo {
  constructor(
    public readonly minIntegerDigits: number,
    public readonly minFractionDigits: number,
    public readonly maxFractionDigits: number,
  ) {}

  public static fromString(digitsInfo: string): DigitsInfo | undefined {
    const regexMatch = regExp.exec(digitsInfo);
    if (!regexMatch || regexMatch.length !== 4) {
      return undefined;
    }

    return new DigitsInfo(
      Number(regexMatch[1]),
      Number(regexMatch[2]),
      Number(regexMatch[3]),
    );
  }

  public transform(digitsToAdd: DigitsTransformationInfo): DigitsInfo {
    return new DigitsInfo(
      this.minIntegerDigits + (digitsToAdd.minIntegerDigitsToAdd ?? 0),
      this.minFractionDigits + (digitsToAdd.minFractionDigitsToAdd ?? 0),
      this.maxFractionDigits + (digitsToAdd.maxFractionDigitsToAdd ?? 0),
    );
  }

  public toString(): string {
    return `${this.minIntegerDigits}.${this.minFractionDigits}-${this.maxFractionDigits}`;
  }
}
