import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';

// tslint:disable:no-any
export abstract class ReactiveForm<T> {
  // tslint:enable:no-any
  public form: UntypedFormGroup;

  constructor(fb: UntypedFormBuilder) {
    this.form = fb.group(this.getFormStructure(fb));
  }

  // tslint:disable:no-commented-code
  // tslint:disable:no-non-null-assertion
  public getControl(key: keyof T): UntypedFormControl {
    return this.form.get(key as string)! as UntypedFormControl;
  }

  public getFormGroup(key: keyof T): UntypedFormGroup {
    return this.form.get(key as string)! as UntypedFormGroup;
  }

  public getArrayControl(key: keyof T): UntypedFormArray {
    return this.form.get(key as string)! as UntypedFormArray;
  }
  // tslint:enable:no-non-null-assertion
  // tslint:enable:no-commented-code

  protected abstract getFormStructure(fb: UntypedFormBuilder): {
    [formKey in keyof T]:
      | UntypedFormArray
      | UntypedFormGroup
      | UntypedFormControl;
  };
}

/** Could be use in case when you're not sure on controlName you would have
 * For example: if controlName would be like `anything Anything.Part of anything` according to
 * `.get()` API of Reactive Forms it will split it and logic would not be as one expected
 */
export const getFormControlFromControls = (
  formGroup: UntypedFormGroup,
  controlName: string,
): AbstractControl | null => {
  return formGroup.controls[controlName] ?? null;
};
