export const COMMA_SEPARATOR = ',';
export const SPACE_SEPARATOR = ' ';

export const parseCommaOrSpacedString = (value: string): string[] => {
  value = value.trim();

  if (value.includes(COMMA_SEPARATOR)) {
    return value
      .split(`${COMMA_SEPARATOR}`)
      .filter(Boolean)
      .map((str) => str.trim());
  }

  if (value.includes(SPACE_SEPARATOR)) {
    return value
      .split(`${SPACE_SEPARATOR}`)
      .filter(Boolean)
      .map((str) => str.trim());
  }

  return [value];
};
