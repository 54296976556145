import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isAPIRequest, isUnprotectedResource } from '@epicuro-next/utilities';
import { select, Store } from '@ngrx/store';
import { switchMap, take } from 'rxjs/operators';

import { SessionState } from '../state/model';
import { selectSessionState } from '../state/session.selectors';

/**
 * Intercept API requests and append the session headers to it
 */
@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  constructor(private store: Store<SessionState>) {}

  /**
   * The intercept method to be called by angular
   */
  public intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    const hostName = window.location.hostname;
    if (!isAPIRequest(request, hostName) || isUnprotectedResource(request)) {
      return next.handle(request);
    }

    return this.store.pipe(
      select(selectSessionState),
      take(1),
      switchMap(({ accountId, siteId, subSiteId, basketId }) => {
        let headers = request.headers;

        if (accountId) {
          headers = headers.set('Epc-Account-Id', `${accountId}`);
        }

        if (basketId) {
          headers = headers.set('Epc-Basket-Id', `${basketId}`);
        }

        if (siteId) {
          headers = headers.set('Epc-Site-Id', `${siteId}`);
        }

        if (subSiteId) {
          headers = headers.set('Epc-Sub-Site-Id', `${subSiteId}`);
        }

        return next.handle(request.clone({ headers }));
      }),
    );
  }
}
