import { Pipe, PipeTransform } from '@angular/core';
import { MessageId } from '@angular/localize';
import { LocalizeFn } from '@angular/localize/init';
import { isNonNullish } from '@epicuro-next/utilities';
import { ParsedTranslation } from '@locl/cli/cmds/convert/translations';

import { $simplifiedLocalize } from './simplified-localize';

export const LOCALE_PREFIX_REGEXP = /@@/;

export type LocaliseFnType = LocalizeFn & {
  TRANSLATIONS: Record<MessageId, ParsedTranslation>;
};

@Pipe({
  name: 'dynamicLocalePipe',
  pure: true,
  standalone: true,
})
export class DynamicLocalePipe implements PipeTransform {
  public transform(
    messageId: string | null | undefined,
    expressions?: unknown[],
  ): string {
    if (messageId == null) return '';

    const localeMessageId = messageId.replace(LOCALE_PREFIX_REGEXP, '');

    if (($localize as LocaliseFnType).TRANSLATIONS) {
      const parsedTranslation = ($localize as LocaliseFnType).TRANSLATIONS[
        localeMessageId
      ];

      if (isNonNullish(parsedTranslation)) {
        return $simplifiedLocalize(
          parsedTranslation.messageParts,
          ...(expressions ?? []).slice(
            0,
            parsedTranslation.placeholderNames.length,
          ),
        );
      }
    }

    return localeMessageId;
  }
}
