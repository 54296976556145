import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { resetStateMetaReducer } from '@epicuro-next/state/meta-reducers';

import { SessionInterceptor } from './interceptors/session.interceptor';
import {
  initialState,
  reducer,
  sessionFeatureKey,
} from './state/session.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(sessionFeatureKey, reducer, {
      metaReducers: [resetStateMetaReducer(initialState)],
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true,
    },
  ],
})
export class SessionStateModule {}
