import { utcDateTime } from '../utc-date-time';

export const getUtcDayTime = (
  utcDate: string,
  isEndOfDay: boolean = false,
): string => {
  const utcInDateTime = utcDateTime(utcDate);
  const startOfDay = utcInDateTime.startOf('day');
  const endOfDay = utcInDateTime.endOf('day');

  return isEndOfDay
    ? endOfDay.set({ millisecond: 0 }).toISO({
        suppressMilliseconds: true,
      })
    : startOfDay.set({ hour: 0 }).toISO({
        suppressMilliseconds: true,
      });
};
