import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EventHubProducerClient, TokenCredential } from '@azure/event-hubs';
import {
  PulseConfigurationKeys,
  PulseConfigurations,
  PULSE_FEATURE_NAME,
} from '@epicuro-next/constants/rule-engine/pulse-rules';
import {
  ITrackEventPayload,
  ITrackRequestPayload,
} from '@epicuro-next/models/platform/monitoring';
import { ConfigurationsService } from '@epicuro-next/platform/configuration';
import { isNonNullish } from '@epicuro-next/utilities';
import * as Sentry from '@sentry/angular';
import { combineLatest, of, Subject, Subscription } from 'rxjs';
import {
  bufferTime,
  catchError,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { AbstractAnalyticsService } from '../abstract/abstract-analytics.service';
import { REPORTING_TOKEN_API_TOKEN } from '../tokens';

import {
  AzureEventHubConfiguration,
  AzureEventHubConfigurationKeys,
  AZURE_EVENT_HUB_NAMESPACE,
  DEFAULT_EVENT_HUB_NAME,
  EVENT_HUB_DOMAIN,
} from './configurations';

@Injectable()
export class AzureEventhubService extends AbstractAnalyticsService {
  private eventBuffer$: Subject<ITrackEventPayload | ITrackRequestPayload> =
    new Subject();

  private eventHubName$ = this.config.getConfigValue<
    PulseConfigurations,
    PulseConfigurationKeys.TrackingHostEventHubName
  >({
    featureName: PulseConfigurationKeys.TrackingHostEventHubName,
    namespace: PULSE_FEATURE_NAME,
    defaultValue: DEFAULT_EVENT_HUB_NAME,
  });

  private namespace$ = this.config.getConfigValue<
    PulseConfigurations,
    PulseConfigurationKeys.TrackingHostNamespace
  >({
    featureName: PulseConfigurationKeys.TrackingHostNamespace,
    namespace: PULSE_FEATURE_NAME,
    defaultValue: false,
  });

  private featureToggle$ = this.config.getConfigValue({
    featureName: PulseConfigurationKeys.EventHubTracking,
    namespace: PULSE_FEATURE_NAME,
    defaultValue: false,
  });

  private disabledEventNames$ = this.config
    .getConfigValue<
      AzureEventHubConfiguration,
      AzureEventHubConfigurationKeys.DisabledEventNames
    >({
      namespace: AZURE_EVENT_HUB_NAMESPACE,
      featureName: AzureEventHubConfigurationKeys.DisabledEventNames,
      defaultValue: [],
    })
    .pipe(
      distinctUntilChanged(
        // tslint:disable-next-line:no-misleading-array-reverse
        (a, b) => JSON.stringify(a.sort()) === JSON.stringify(b.sort()),
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

  private client: EventHubProducerClient;
  private token: {
    token: string;
    expiresOnTimestamp: number;
  };
  private bufferSub: Subscription;

  constructor(
    private config: ConfigurationsService,
    private httpClient: HttpClient,
    @Inject(REPORTING_TOKEN_API_TOKEN) private url: string,
  ) {
    super();

    combineLatest([
      this.namespace$.pipe(filter(filterIfString)),
      this.eventHubName$,
    ])
      .pipe(take(1))
      .subscribe(([namespace, eventHubName]) => {
        this.initialize(namespace, eventHubName);
      });
  }

  private initialize(namespace: string, hubName: string) {
    const host = `${namespace}.${EVENT_HUB_DOMAIN}`;

    const tokenProvider = {
      getToken: async () => {
        return !this.token?.expiresOnTimestamp || this.isTokenExpired()
          ? this.httpClient
              .post(this.url, {
                audience: `${host}/${hubName}`,
              })
              .pipe(
                map((response: { sas: string; expiry: number }) => ({
                  token: response.sas,
                  expiresOnTimestamp: response.expiry,
                })),
                tap((token) => {
                  this.token = token;
                }),
                catchError((e) => {
                  Sentry.captureException(e);
                  return of();
                }),
              )
              .toPromise()
          : new Promise(() => this.token);
      },
    } as TokenCredential;
    this.client = new EventHubProducerClient(host, hubName, tokenProvider);

    this.bufferSub = this.eventBuffer$
      .pipe(
        bufferTime(1000),
        withLatestFrom(this.featureToggle$),
        filter(
          ([events, shouldSend]: [ITrackEventPayload[], boolean]) =>
            !!events.length && shouldSend,
        ),
        switchMap(([events]) => this.sendMetric(events)),
      )
      .subscribe();
  }

  private sendMetric(payload: unknown[]) {
    const eventData = payload.map((data) => ({ body: data }));
    return this.client.sendBatch(eventData);
  }

  public trackEvent(trackEventPayload: ITrackEventPayload) {
    this.disabledEventNames$
      .pipe(filter(isNonNullish), take(1))
      .subscribe((disabledEventNames = []) => {
        const bypassEvent = disabledEventNames.some(
          (name) => name === trackEventPayload.name,
        );

        if (!bypassEvent) {
          this.eventBuffer$.next(trackEventPayload);
        }
      });
  }

  public trackRequestMetrics(trackRequestPayload: ITrackRequestPayload) {
    this.eventBuffer$.next(trackRequestPayload);
  }

  public destroy() {
    this.bufferSub?.unsubscribe();
  }

  private isTokenExpired() {
    return Date.now() > this.token.expiresOnTimestamp * 1000;
  }
}

function filterIfString(value: string | boolean): value is string {
  return typeof value !== 'boolean';
}
