import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { StorageModule } from '@epicuro-next/platform/storage';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { I18nService } from './i18n.service';
import { AcceptLanguageInterceptor } from './interceptors/accept-language.interceptor';
import { LanguageEffects } from './store/i18n.effects';
import { i18nFeatureKey, languageReducer } from './store/i18n.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(i18nFeatureKey, languageReducer),
    EffectsModule.forFeature([LanguageEffects]),
    StorageModule,
  ],
  providers: [
    I18nService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcceptLanguageInterceptor,
      multi: true,
    },
  ],
})
export class I18nModule {}
