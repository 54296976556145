import { InjectionToken } from '@angular/core';

import { GCRConfig } from './interfaces/gcr-config';
import { GTAGConfig } from './interfaces/gtag-config';
import { GTMConfig } from './interfaces/gtm-config';

export const GTM_GONFIG_TOKEN = new InjectionToken<GTMConfig>(
  'Google Tag Manager Config',
);

export const GTAG_CONFIG_TOKEN = new InjectionToken<GTAGConfig[]>(
  'Google Tag Config',
);

export const GCR_CONFIG_TOKEN = new InjectionToken<GCRConfig>(
  'Google Cloud Retail Config',
);
