import { Injectable } from '@angular/core';
import {
  ITrackEventPayload,
  ITrackRequestPayload,
} from '@epicuro-next/models/platform/monitoring';

import { AbstractAnalyticsService } from '../../abstract/abstract-analytics.service';

import { AppInsightsService } from './app-insights.service';

/**
 * AppInsights service for analytics
 */
@Injectable()
export class AppInsightsAnalyticsService extends AbstractAnalyticsService {
  /**
   * Constructor
   * @param appInsightsService
   */
  constructor(private appInsightsService: AppInsightsService) {
    super();
  }

  /**
   * Tracks a specific event.
   * @param trackEventPayload
   */
  public trackEvent(trackEventPayload: ITrackEventPayload) {
    this.appInsightsService.instance.trackEvent(trackEventPayload);
  }

  /**
   * Tracks the payload of a request
   * @param trackRequestPayload
   */
  public trackRequestMetrics(trackRequestPayload: ITrackRequestPayload) {
    this.appInsightsService.instance.trackDependencyData(trackRequestPayload);
  }
}
