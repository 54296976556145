import {
  faArrowRight,
  faBadgePercent,
  faBookmark,
  faCloudArrowUp,
  faCloudCheck,
  faFolderBlank,
  faGhost,
  faGrid2,
  faHeart,
  faLightbulbOn,
  faMagnifyingGlass,
  faRightToBracket,
  faSquare,
  faStar,
} from '@fortawesome/pro-regular-svg-icons';

export const far = {
  [faArrowRight.iconName]: faArrowRight,
  [faBadgePercent.iconName]: faBadgePercent,
  [faBookmark.iconName]: faBookmark,
  [faCloudArrowUp.iconName]: faCloudArrowUp,
  [faCloudCheck.iconName]: faCloudCheck,
  [faFolderBlank.iconName]: faFolderBlank,
  [faGhost.iconName]: faGhost,
  [faGrid2.iconName]: faGrid2,
  [faLightbulbOn.iconName]: faLightbulbOn,
  [faMagnifyingGlass.iconName]: faMagnifyingGlass,
  [faSquare.iconName]: faSquare,
  [faStar.iconName]: faStar,
  [faHeart.iconName]: faHeart,
  [faRightToBracket.iconName]: faRightToBracket,
};
