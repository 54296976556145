import { InjectionToken } from '@angular/core';

import { EnvironmentSettings } from './environment-settings';

/**
 * Injection token for the environment data
 */
export const ENVIRONMENT_API = new InjectionToken<string>(
  'Environment API url',
);

/**
 * Injection token for the environment data
 */
export const ENVIRONMENT_SETTINGS = new InjectionToken<EnvironmentSettings>(
  'Environment Settings',
);
