import { Injectable } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import * as icons from './custom-icons';

type IconsMap = { [iconId: string]: IconDefinition };
@Injectable()
export class IconsService {
  public getIcon(id: string) {
    return (icons as IconsMap)[id];
  }
}
