import { Configurations } from '../models/config.model';

export const SCANNER_RULE_ENGINE_FEATURE_NAME = 'scanner';

/**
 * configuration keys for this module
 */
export enum ScannerConfigurationKeys {
  ScanToBasketEnabled = 'scan-to-basket-enabled',
  ScanToListEnabled = 'scan-to-list-enabled',
  OpenConfigStripFromScannerEnabled = 'open-cs-from-scanner-enabled',
  SyncScannedBasketProductsQuantity = 'sync-scanned-basket-products-quantity',
}

/**
 * Configuration return types and default values for this module
 */
export interface ScannerConfigurations
  extends Configurations<ScannerConfigurationKeys> {
  [ScannerConfigurationKeys.ScanToBasketEnabled]: boolean;
  [ScannerConfigurationKeys.ScanToListEnabled]: boolean;
  [ScannerConfigurationKeys.OpenConfigStripFromScannerEnabled]: boolean;
  [ScannerConfigurationKeys.SyncScannedBasketProductsQuantity]: boolean;
}

export const scannerConfigurationsDefaultValues = {
  [ScannerConfigurationKeys.ScanToBasketEnabled]: false,
  [ScannerConfigurationKeys.ScanToListEnabled]: false,
  [ScannerConfigurationKeys.OpenConfigStripFromScannerEnabled]: false,
};
