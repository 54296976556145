import { EnvironmentSettings } from '@epicuro-next/platform/environment';

import { GCRConfig } from '../interfaces/gcr-config';

export function GCRConfigFactory(
  environmentSettings: EnvironmentSettings,
): GCRConfig | null {
  if (!environmentSettings.gcr) {
    return null;
  }

  try {
    return JSON.parse(environmentSettings.gcr) as GCRConfig;
  } catch (error) {
    console.error(error);
    return null;
  }
}
