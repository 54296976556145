import { Params } from '@angular/router';

import { decodeUriComponentRecursively } from './encoding';

export type LinkInfo = {
  url: string;
  params?: Params;
};

export function urlToLinkInfo(urlWithOptionalQueryParams: string) {
  const [url, queryString] = urlWithOptionalQueryParams.split('?');

  const params: Params = {};

  const linkInfo: LinkInfo = {
    url,
    params,
  };

  if (Boolean(queryString)) {
    const keyValuePairs = queryString.split('&');
    for (const kvp of keyValuePairs) {
      const [key, value] = kvp.split('=');

      if (value === 'true') {
        params[key] = true;
      } else if (value === 'false') {
        params[key] = false;
      } else if (value === '') {
        params[key] = '';
      } else if (!isNaN(Number(value))) {
        params[key] = Number(value);
      } else {
        params[key] = decodeUriComponentRecursively(value);
      }
    }
  }

  return linkInfo;
}
