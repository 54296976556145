import { Inject, Injectable } from '@angular/core';
import {
  ApplicationInsights,
  Snippet,
} from '@microsoft/applicationinsights-web';

import { APPINSIGHTS_CONFIG } from './tokens';

/**
 * Service to initialize ApplicationInsights
 */
@Injectable()
export class AppInsightsService {
  /** Instance of ApplicationInsights */
  public instance: ApplicationInsights;

  /**
   * Constructor, initializes ApplicationInsights
   * @param settings
   */
  constructor(@Inject(APPINSIGHTS_CONFIG) settings: Snippet) {
    if (!settings || !settings.config || !settings.config.instrumentationKey) {
      settings = {
        config: {
          instrumentationKey: '',
          disableAjaxTracking: true,
        },
      };
    }

    this.instance = new ApplicationInsights(settings);

    this.instance.loadAppInsights();
  }
}
