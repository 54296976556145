import { Action, createReducer, on } from '@ngrx/store';

import { Configurations } from '../models/config.model';

import {
  initConfig,
  initConfigSuccess,
  upsertToConfig,
} from './configuration.actions';

/**
 * State slice key
 */
export const configurationFeatureKey = 'configuration';

/**
 * Type to represent the state
 */
export type ConfigurationState = {
  [index: string]: Configurations | undefined;
};

/**
 * Initial state
 */
export const initialState: ConfigurationState = {};

/**
 * The main reducer for the configuration module actions
 */
const configurationReducer = createReducer(
  initialState,
  on(initConfigSuccess, (state, { configurations }): ConfigurationState => {
    return { ...state, ...configurations } as ConfigurationState;
  }),
  on(initConfig, (state, { featureName }) => {
    return state[featureName]
      ? state
      : {
          ...state,
          [featureName]: undefined,
        };
  }),
  on(
    upsertToConfig,
    (state, { namespace, featureName, value }): ConfigurationState => {
      const currentNamespace = state[namespace] || {};
      const newNamespace = {
        ...currentNamespace,
        [featureName]: value,
      };

      return {
        ...state,
        [namespace]: newNamespace,
      };
    },
  ),
);

/**
 * Exported function because of Angular AOT
 */
export function reducer(state: ConfigurationState | undefined, action: Action) {
  return configurationReducer(state, action);
}
