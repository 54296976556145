export enum EcommerceTrackingEvents {
  ProductDetailView = 'productDetailView',
  AddToCart = 'addToCart',
  RemoveFromCart = 'removeFromCart',
  BasketContains = 'basketContains',
  Checkout = 'checkout',
  Purchase = 'purchase',
  PromotionClick = 'promotionClick',
  ProductClick = 'productClick',
  DeleteBasket = 'deleteBasket',
}
