import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CountryCode } from './models';

const flagsMap: Record<string, string> = {
  ja: 'jp',
};

@Component({
  selector: 'atx-flag-icon',
  styleUrls: ['./flag-icon.component.scss'],
  template: `<span class="fi fi-{{ code | lowercase }}"></span>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlagIconComponent {
  @Input()
  public countryCode?: CountryCode;

  public get code() {
    if (!this.countryCode) {
      return;
    }

    if (flagsMap[this.countryCode]) {
      return flagsMap[this.countryCode];
    }

    return this.countryCode;
  }
}
