import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Directive()
export abstract class SvgLoader implements OnChanges {
  public svg?: Promise<SafeHtml | null>;

  @Input() public errorMessage = 'Unable to load svg for the given file.';

  constructor(protected sanitizer: DomSanitizer) {}

  public async getSvg(...args: string[]) {
    try {
      const content = await this.loadSvgContent(...args);
      return this.sanitizer.bypassSecurityTrustHtml(content);
    } catch {
      console.error(this.errorMessage);
      return null;
    }
  }

  public abstract ngOnChanges(changes: SimpleChanges): void;

  protected abstract loadSvgContent(...args: unknown[]): Promise<string>;
}
