import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { CustomIconComponent } from './components/custom-icon/custom-icon.component';
import { Image360IconComponent } from './components/image-360/image-360.component';
import { ColorsIconComponent } from './custom-icons/stacked-icons/colors/colors-icon.component';
import { ZoomDocumentIconComponent } from './custom-icons/stacked-icons/zoom-document/fa-zoom-document.icon';
import { FlagIconComponent } from './flag/flag-icon.component';
import { IconsService } from './icons.service';

const PUBLIC_DECLARATIONS = [
  ColorsIconComponent,
  ZoomDocumentIconComponent,
  FlagIconComponent,
  CustomIconComponent,
  Image360IconComponent,
];
@NgModule({
  declarations: PUBLIC_DECLARATIONS,
  imports: [CommonModule, FontAwesomeModule],
  providers: [IconsService],
  exports: [FontAwesomeModule, ...PUBLIC_DECLARATIONS],
})
export class IconsModule {}
