import {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core';

/** copy to one icon */
export const faCopyToOne: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'copy-to-one' as IconName,
  icon: [
    16,
    16,
    [],
    '',
    'M13 5.5C13 4.12151 11.8785 3 10.5 3H4V1.5C4 0.67157 4.67157 0 5.5 0H14.5C15.3284 0 16 0.67157 16 1.5V10.5C16 11.3284 15.3284 12 14.5 12H13V5.5ZM0 14.5C0 15.3284 0.67157 16 1.5 16H10.5C11.3284 16 12 15.3284 12 14.5V5.5C12 4.67155 11.3284 4 10.5 4H1.5C0.67157 4 0 4.67157 0 5.5V14.5Z',
  ],
};
