import { Configurations } from '../models/config.model';

export const NAMESPACE_CONNECT = 'connect';

export enum ConnectConfigurationKeys {
  ConnectEnabled = 'enabled',
}

export interface ConnectConfigurations
  extends Configurations<ConnectConfigurationKeys> {
  [ConnectConfigurationKeys.ConnectEnabled]: boolean;
}

export const CONNECT_VISIBLE_AVATARS = 3;

export enum ConnectNotificationType {
  SetConnectionStatus = 'SetConnectionStatus',
  InitiateVideoCall = 'InitiateVideoCall',
  DeclineVideoCall = 'DeclineVideoCall',
  CancelVideoCall = 'CancelVideoCall',
}

export enum ConnectMessageType {
  OnConnectionStatusChange = 'OnConnectionStatusChange',
  OnInitiateVideoCall = 'OnInitiateVideoCall',
  OnDeclineVideoCall = 'OnDeclineVideoCall',
  OnCancelVideoCall = 'OnCancelVideoCall',
  OnConnectionShutdown = 'OnConnectionShutdown',
}

export const connectScreenShareResolution = {
  width: 1280,
  height: 720,
};

export const CONNECT_CALL_NOTIFICATION_DISMISS_TIME = 15000;
