import {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core';

/** copy icon */
export const faCopy: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'copy' as IconName,
  icon: [
    16,
    16,
    [],
    '',
    'M10,4 L10,0 L3,0 L0,3 L0,12 L6,12 L6,16 L16,16 L16,4 L10,4 Z M3,1.414 L3,3 L1.414,3 L3,1.414 Z M1,11 L1,4 L4,4 L4,1 L9,1 L9,4 L6,7 L6,11 L1,11 Z M9,5.414 L9,7 L7.414,7 L9,5.414 Z M15,15 L7,15 L7,8 L10,8 L10,5 L15,5 L15,15 Z',
  ],
};
