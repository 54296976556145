import { InjectionToken } from '@angular/core';
import { StoreConfig } from '@ngrx/store';

import { ConfigurationState } from './state/configuration.reducer';

export const FEATURE_NAME_TOKEN = new InjectionToken<string>('Feature name');
export const FEATURES_NAME_TOKEN = new InjectionToken<string>('Features');
export const APP_NAME_TOKEN = new InjectionToken<string>('App Name');

export const CONFIGURATIONS_STORAGE_KEY = new InjectionToken<string>(
  'ConfigurationsStorage',
);

export const CONFIGURATIONS_CONFIG_TOKEN = new InjectionToken<
  StoreConfig<ConfigurationState>
>('ConfigurationsConfigToken');
