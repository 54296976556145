import { PrimitiveObject } from '@epicuro-next/models/engagement';

export function sortArrayByArrayWithProp(
  toSort: PrimitiveObject[],
  sortBy: PrimitiveObject[],
  toSortProp: string,
  sortByProp?: string,
) {
  if (sortByProp === undefined) sortByProp = toSortProp;
  return toSort.sort((curr: PrimitiveObject, next: PrimitiveObject) => {
    const indexCurr = sortBy.findIndex(
      (obj) => obj[sortByProp as string] === curr[toSortProp],
    );
    const indexNext = sortBy.findIndex(
      (obj) => obj[sortByProp as string] === next[toSortProp],
    );
    return (
      (indexCurr > -1 ? indexCurr : Infinity) -
      (indexNext > -1 ? indexNext : Infinity)
    );
  });
}
