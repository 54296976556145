import { Inject, Injectable, Optional } from '@angular/core';
import {
  IKeyValue,
  IUserContext,
} from '@epicuro-next/models/platform/monitoring';

import { AbstractContextService } from '../abstract/abstract-context.service';
import { CONTEXT_TRANSPORT_TOKEN } from '../tokens';

/**
 * Facade service for managing loggers context. Uses Angular dependency injection to get registered context services.
 * Services should extend AbstractContextService and be provided for `CONTEXT_TRANSPORT_TOKEN` with `multi: true` option
 */
@Injectable()
export class MonitoringContextService extends AbstractContextService {
  /**
   * Constructor
   * @param transports
   */
  constructor(
    @Optional()
    @Inject(CONTEXT_TRANSPORT_TOKEN)
    private transports: AbstractContextService[],
  ) {
    super();

    if (!transports) {
      this.transports = [];
    }
  }

  /**
   * Sets the user context
   * @param user
   */
  public setUser(user: IUserContext): void {
    this.transports.forEach((transport: AbstractContextService) => {
      try {
        transport.setUser(user);
      } catch (e) {}
    });
  }

  /**
   * Sets the tags to be used by the loggers
   * @param tags
   */
  public setTags(tags: IKeyValue): void {
    this.transports.forEach((transport: AbstractContextService) => {
      try {
        transport.setTags(tags);
      } catch (e) {}
    });
  }

  /**
   * Sets the release version of the application
   * @param releaseVersion
   */
  public setRelease(releaseVersion: string): void {
    this.transports.forEach((transport: AbstractContextService) => {
      try {
        transport.setRelease(releaseVersion);
      } catch (e) {}
    });
  }
}
