import { IImageBreakpoint } from '@epicuro-next/ataraxia/models';

/**
 * Centralised image max sizes for more cache hits
 */
export const IMAGE_MAX_SIZES = {
  XXLARGE: 1920,
  XLARGE: 1400,
  LARGE: 1200,
  NORMAL: 992,
  SMALL: 768,
  XSMALL: 576,
  XXSMALL: 400,
  XXXSMALL: 320,
  THUMBNAIL: 200,
  AVATAR: 120,
  ICON: 50,
};

export const xxlargeBreakpoint: IImageBreakpoint = {
  mediaMinWidth: '1400px',
  width: IMAGE_MAX_SIZES.XXLARGE,
  mode: 'max',
};

export const xlargeBreakpoint: IImageBreakpoint = {
  mediaMinWidth: '1200px',
  width: IMAGE_MAX_SIZES.XLARGE,
  mode: 'max',
};

export const largeBreakpoint: IImageBreakpoint = {
  mediaMinWidth: '992px',
  width: IMAGE_MAX_SIZES.XLARGE,
  mode: 'max',
};

export const normalBreakpoint: IImageBreakpoint = {
  mediaMinWidth: '768px',
  width: IMAGE_MAX_SIZES.NORMAL,
  mode: 'max',
};

export const smallBreakpoint: IImageBreakpoint = {
  mediaMinWidth: '576px',
  width: IMAGE_MAX_SIZES.SMALL,
  mode: 'max',
};

export const xsmallBreakpoint: IImageBreakpoint = {
  mediaMinWidth: '320px',
  width: IMAGE_MAX_SIZES.XSMALL,
  mode: 'max',
};

export const xxsmallBreakpoint: IImageBreakpoint = {
  mediaMinWidth: '160px',
  width: IMAGE_MAX_SIZES.XXSMALL,
  mode: 'max',
};

export const xxxsmallBreakpoint: IImageBreakpoint = {
  mediaMinWidth: '120px',
  width: IMAGE_MAX_SIZES.XXXSMALL,
  mode: 'max',
};

export const iconBreakpoint: IImageBreakpoint = {
  mediaMinWidth: '50px',
  width: IMAGE_MAX_SIZES.ICON,
  height: IMAGE_MAX_SIZES.ICON,
  mode: 'max',
  maxHeight: IMAGE_MAX_SIZES.ICON,
  maxWidth: IMAGE_MAX_SIZES.ICON,
};

export const defaultImageBreakpoints: IImageBreakpoint[] = [
  xxlargeBreakpoint,
  xlargeBreakpoint,
  largeBreakpoint,
  normalBreakpoint,
  smallBreakpoint,
  xsmallBreakpoint,
  xxsmallBreakpoint,
  xxxsmallBreakpoint,
];
