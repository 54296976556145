export function toTruthTableValue(inputWithMsbFirst: boolean[]): number {
  let outcome = 0;
  const highestBitSignificance = inputWithMsbFirst.length - 1;
  // Inverted to have the MSB first
  for (let i = highestBitSignificance; i >= 0; i--) {
    const nrOfBitsToShift = highestBitSignificance - i;
    const bitValue = inputWithMsbFirst[i] ? 1 : 0;
    // tslint:disable:no-bitwise
    const significantValue = bitValue << nrOfBitsToShift;
    // tslint:disable:no-bitwise
    outcome = outcome | significantValue;
  }

  return outcome;
}
