import { Action, createReducer, on } from '@ngrx/store';

import { ILanguageEntry } from '../i18n.interface';
import { getCurrentLanguage } from '../i18n.utils';

import { languageActions } from './i18n.actions';

/**
 * State slice key
 */
export const i18nFeatureKey = 'i18n';

/**
 * Language state
 */
export class LanguageState {
  /** selected language */
  public selected: string;
  /** list of available languages */
  public availables: ILanguageEntry[];
}

/**
 * Method to initialize the state
 */
export const initializeState = (): LanguageState => {
  return { selected: getCurrentLanguage(), availables: [] };
};

/**
 * Reducer for internationalization
 */
const reducer = createReducer(
  initializeState(),
  on(languageActions.setLanguage, (state: LanguageState, { selected }) => {
    return {
      ...state,
      selected,
    };
  }),
  on(
    languageActions.setAvailableLanguages,
    (state: LanguageState, { availables }) => {
      return {
        ...state,
        availables: [...availables],
      };
    },
  ),
);

/** Language reducer */
export function languageReducer(
  state: LanguageState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
