import { registerLocaleData } from '@angular/common';
import { fetchTranslations, getBrowserLang } from '@locl/core';

/**
 * Static url for targeting the translations
 */
const translationsPath = `/static/i18n/`;

/**
 * default locale picked as default.
 */
const defaultLocale = 'en';

/**
 * Loads translations from remote
 */
export async function lazyLoadTranslations() {
  const locale = getCurrentLanguage();
  let data;

  try {
    data = await fetchTranslations(`${translationsPath}${locale}.json`);
  } catch (err) {
    data = await fetchTranslations(`${translationsPath}${defaultLocale}.json`);
  }

  await initializeLocale(data.locale);
}

/**
 * Retrieve current language from storage
 */
export function getCurrentLanguage(): string {
  let currentLanguage =
    localStorage.getItem('phoenix-language') ||
    localStorage.getItem('language');

  if (!currentLanguage) {
    currentLanguage = getBrowserLang();
  }
  if (!currentLanguage) {
    currentLanguage = defaultLocale;
  }

  const split = currentLanguage.split('-');

  if (split.length === 1) {
    return currentLanguage;
  }

  return split
    .map((item, index) => {
      if (index === split.length - 1) {
        return item.toUpperCase();
      }

      return item;
    })
    .join('-');
}

/**
 * Load the appropriate locale module for pipe localization
 */
export function initializeLocale(localeId: string): Promise<void> {
  return import(
    /* webpackMode: "lazy" */
    `node_modules/@angular/common/locales/${localeId}.mjs`
  )
    .catch(() => {
      return import(
        /* webpackMode: "lazy" */
        `node_modules/@angular/common/locales/${defaultLocale}.mjs`
      );
    })
    .then((localeModule) => registerLocaleData(localeModule.default));
}

// As localize does not support ICU on component level
// based on https://github.com/angular/angular/issues/35912#issuecomment-595867379
export function plural<T extends number | string>(
  value: T,
  options: { [key in T]: string },
  fallback: string,
) {
  // Handle 0, 1, ... cases
  const directResult = options[value];
  if (directResult !== undefined) {
    return directResult;
  }
  // handle zero, one, two, few, many
  // ...
  return fallback;
}
