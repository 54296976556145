import { Renderer2, RendererFactory2 } from '@angular/core';

import { IGtmProperties } from '../interfaces/properties';
import { LayerType } from '../models';
import { TrackingConfig } from '../models/tracking-config';

export abstract class AbstractTrackClient {
  protected trackingStarted = false;
  protected readonly renderer: Renderer2;

  constructor(protected rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public abstract startTracking(): void;

  public abstract sendTrackEvent(
    payload: IGtmProperties | TrackingConfig,
    layerType?: LayerType,
  ): void;
}
