import { ComponentStore } from '@ngrx/component-store';
import { Observable, tap, throwError } from 'rxjs';

type Unpacked<T> = T extends ComponentStore<infer U> ? U : T;

export function tapComponentStoreLoading<T, S extends ComponentStore<object>>(
  store: S,
  property: keyof Unpacked<S>,
  value: boolean,
) {
  const setLoading = () => {
    store.patchState({
      [property]: value,
    });
  };
  return (source$: Observable<T>) => {
    return source$.pipe(
      tap({
        next: () => {
          setLoading();
        },
        error: (error) => {
          setLoading();
          return throwError(() => new Error(error));
        },
      }),
    );
  };
}
