import { SimpleChanges } from '@angular/core';

export function inputHasChanged<T extends object>(
  changes: SimpleChanges,
  ...keys: (keyof T)[]
): boolean {
  for (const key of keys) {
    const change = changes[key as string];

    if (change) {
      const hasChanged =
        change.firstChange || change.currentValue !== change.previousValue;

      return hasChanged;
    }
  }

  return false;
}
